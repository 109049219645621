/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';

import { Box, Typography, Tooltip } from '@mui/material';

import './styles.scss';

// prettier-ignore
const HomeEventsList = ({
  eventsList,
  setDateRange,
  isScreenBig,
  ateliersList
}) => {
  const thisMonthEvents = useMemo(
    () => eventsList.filter((event) => dayjs(event.dateStart) < dayjs().endOf('month')),
    [eventsList]
  );

  const nextMonthEvents = useMemo(
    () => eventsList.filter((event) => dayjs(event.dateStart).get('month') === dayjs().get('month') + 1),
    [eventsList]
  );

  // eslint-disable-next-line max-len
  const thisWeekEvents = useMemo(
    // prettier-ignore
    () => eventsList.filter((event) => dayjs(event.dateStart) < dayjs().endOf('week') && dayjs(event.dateEnd) >= dayjs()),
    [eventsList]
  );

  // prettier-ignore
  const EventComponent = ({
    title, start, end, subcategory, id, display, index
  }) => {
    const [displayDate, setDisplayDate] = useState('');

    useEffect(() => {
      const range = `${dayjs(start).format('DD')} - ${dayjs(end).format('DD MMMM')}`;

      setDisplayDate(range);
    }, []);

    const dateRange = {
      title,
      start,
      end,
      color: '#f0f',
    };

    return (
      <Tooltip title="Cliquez sur l'événement pour l'afficher dans le calendrier" arrow placement="top">
        <Box
          key={id}
          className="homeEventsList-card"
          onClick={() => {
            setDateRange(dateRange);
          }}
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            maxWidth: '95vw',
            padding: '1rem 2rem',
            // eslint-disable-next-line no-nested-ternary
            color: `${!display ? 'orange' : dayjs().diff(dayjs(end)) > 0 ? '#bf0101' : 'black'}`,
            border: `${!display ? '1px solid orange' : 'none'}`,
          }}
        >
          <Box
            sx={{
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                flexGrow: isScreenBig ? '0' : '1',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                fontSize: isScreenBig ? '1rem' : '0.9rem',
              }}
            >
              {title}
            </Typography>
          </Box>
          {isScreenBig && (
            <Typography sx={{ minWidth: 'max-content' }}>{start && end ? displayDate : 'Sur demande'}</Typography>
          )}
          <Typography
            variant="button"
            sx={{
              textAlign: 'end',
            }}
          >
            <NavLink to={`/${subcategory?.subCatTitle === 'ateliers' ? 'atelier' : 'event'}/${id}/${index % 2 === 0 ? 2 : 1}`}>
              {'Détails'}
            </NavLink>
          </Typography>
        </Box>
      </Tooltip>
    );
  };

  return (
    <Box className="homeEventsList">
      {/* THIS WEEK EVENTS */}
      <Box>
        <h1
          // variant="h1"
          style={{
            fontSize: 'clamp(2rem, 4vw + 1rem, 4rem)',
            fontWeight: '100',
            marginBottom: '1rem',
          }}
        >
          FORMATIONS:
        </h1>
        <h2>Cette semaine :</h2>
        {thisWeekEvents && thisWeekEvents.length > 0 ? (
          thisWeekEvents?.map((event, index) => (
            <EventComponent
              index={index}
              subcategory={event.subcategory}
              key={event._id}
              title={event.title}
              start={event.dateStart}
              duration={event.duration}
              end={event.dateEnd}
              id={event._id}
              location={event.location}
              display={event.display}
            />
          ))
        ) : (
          <Typography padding="1rem">Pas d'événement cette semaine</Typography>
        )}
        {/* THIS MONTH EVENTS */}
        <h2>Ce mois ci :</h2>
        {thisMonthEvents && thisMonthEvents.length > 0 ? (
          thisMonthEvents?.map((event, index) => (
            <EventComponent
              index={index}
              subcategory={event.subcategory}
              key={event._id}
              title={event.title}
              start={event.dateStart}
              end={event.dateEnd}
              id={event._id}
              location={event.location}
              display={event.display}
            />
          ))
        ) : (
          <Typography padding="1rem">Pas d'événement ce mois-ci</Typography>
        )}
        {/* NEXT MONTH */}
        <h2>Le mois prochain :</h2>
        {nextMonthEvents && nextMonthEvents.length > 0 ? (
          nextMonthEvents?.map((event, index) => (
            <EventComponent
              index={index}
              subcategory={event.subcategory}
              key={event._id}
              title={event.title}
              start={event.dateStart}
              end={event.dateEnd}
              id={event._id}
              location={event.location}
              display={event.display}
            />
          ))
        ) : (
          <Typography padding="1rem">Pas d'événement le mois prochain</Typography>
        )}
      </Box>
      <Box>
        <h1
          // variant="h1"
          style={{
            fontSize: 'clamp(2rem, 4vw + 1rem, 4rem)',
            fontWeight: '100',
            marginBottom: '1rem',
          }}
        >
          ATELIERS:
        </h1>
        {ateliersList?.length > 0
          && ateliersList?.map((atelier, index) => (
            <EventComponent
              index={index}
              subcategory={atelier.subcategory}
              key={atelier._id}
              title={atelier.title}
              start={atelier.dateStart}
              end={atelier.dateEnd}
              id={atelier._id}
              location={atelier.location}
              display={atelier.display}
            />
          ))}
      </Box>
    </Box>
  );
};

HomeEventsList.propTypes = {
  // COMPONENT PROPTYPES
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string,
      dateEnd: PropTypes.string,
      duration: PropTypes.string,
      location: PropTypes.string.isRequired,
      price: PropTypes.number,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),
  setDateRange: PropTypes.func.isRequired,
  isScreenBig: PropTypes.bool.isRequired,

  // EVENT ITEM PROPTYPES
  title: PropTypes.string,
  start: PropTypes.string,
  duration: PropTypes.string,
  subcategory: PropTypes.string,
  end: PropTypes.string,
  id: PropTypes.string,
};

HomeEventsList.defaultProps = {
  subcategory: null,
  dateStart: null,
  dateEnd: null,
  duration: null,
  title: null,
  price: null,
  start: null,
  end: null,
  id: null,
};

export default HomeEventsList;
