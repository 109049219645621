import { connect } from 'react-redux';
import BackOffice from '../components/Backoffice';

import { checkLogged } from '../actions/auth';
import { getEventsList } from '../actions/events';
import { getAteliersList } from '../actions/ateliers';
import { setScreenBig } from '../actions/responsive';
import { getCategoriesList, getSubcategorieslist } from '../actions/categories';
import { openSnackbar } from '../actions/snackbar';

const mapStateToProps = (state) => ({
  isAdminLogged: state.admin.isAdminLogged,
  isLoading: state.loading.isLoading,
  isScreenBig: state.responsive.isScreenBig,
});

const mapDispatchToProps = (dispatch) => ({
  checkLogged: () => dispatch(checkLogged()),
  getEventsList: () => dispatch(getEventsList()),
  getAteliersList: () => dispatch(getAteliersList()),
  getCategoriesList: () => dispatch(getCategoriesList()),
  getSubcategorieslist: () => dispatch(getSubcategorieslist()),
  setScreenBig: (payload) => dispatch(setScreenBig(payload)),
  openSnackbar: (payload) => dispatch(openSnackbar(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOffice);
