import { connect } from 'react-redux';
import ResetPwdForm from '../components/ResetPwdForm';

import { resetPassword } from '../actions/auth';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (payload) => dispatch(resetPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPwdForm);
