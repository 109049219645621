// MUI
// prettier-ignore
import {
  Container,
} from '@mui/material';

import CategoriesList from '../../../containers/categoriesListContainer';
// import CreateCategoryForm from '../../../containers/createCategoryForm';
// import CreateSubcategoryForm from '../../../containers/createSubcategoryForm';

const HandleCategoriesComponent = () => (
  <Container
    className="categories-container"
    sx={{
      textAlign: 'start',
    }}
  >
    <CategoriesList />
    {/* <Grid container spacing={0} sx={{ marginTop: '2rem' }}>
      <Grid item sm={12} md={6}>
        <CreateCategoryForm />
      </Grid>
      <Grid item sm={12} md={6}>
        <CreateSubcategoryForm />
      </Grid>
    </Grid> */}
  </Container>
);

export default HandleCategoriesComponent;
