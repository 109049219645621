export const GET_EVENTS_LIST = 'GET_EVENTS_LIST';
export const SET_EVENTS_LIST = 'SET_EVENTS_LIST';
export const CREATE_EVENT = 'CREATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS';
export const SET_EVENT_DETAILS = 'SET_EVENT_DETAILS';

export const setEventDetails = (payload) => ({
  type: SET_EVENT_DETAILS,
  payload,
});

export const getEventDetails = (id) => ({
  type: GET_EVENT_DETAILS,
  id,
});

export const updateEvent = (id, payload) => ({
  type: UPDATE_EVENT,
  id,
  payload,
});

export const deleteEvent = (id) => ({
  type: DELETE_EVENT,
  id,
});

export const createEvent = (payload) => ({
  type: CREATE_EVENT,
  payload,
});

export const setEventsList = (payload) => ({
  type: SET_EVENTS_LIST,
  payload,
});

export const getEventsList = () => ({
  type: GET_EVENTS_LIST,
});
