import PropTypes from 'prop-types';
import dompurify from 'dompurify';

import './styles.scss';

const sanitizeConfig = {
  FORBID_TAGS: ['img', 'script', 'a', 'iframe', 'svg', 'canvas', 'input', 'form'],
  FORBID_ATTR: ['img', 'script', 'a', 'iframe', 'svg', 'canvas', 'input', 'form'],
};

const DangerouslyDisplayHTMLComponent = ({ content }) => (
  <div className="dangerous-html" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(content, sanitizeConfig) }} />
);

DangerouslyDisplayHTMLComponent.propTypes = {
  content: PropTypes.string,
};

export default DangerouslyDisplayHTMLComponent;
