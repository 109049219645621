/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// prettier-ignore

import { Navigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// DAYJS & CALENDARS
import dayjs from 'dayjs';
import * as frLocale from 'dayjs/locale/fr';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/lab';

// MUI
import {
  TextField,
  Container,
  Button,
  FormControl,
  Select,
  Box,
  Switch,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Grid,
  Typography
} from '@mui/material';

import RichTextfieldComponent from '../Utils/UIComponents/RichTextfieldComponent';
import Loading from '../Utils/UIComponents/Loading';

// Svschema
const eventSchema = yup.object().shape({
  title: yup.string(),
  program: yup.string(),
  categories: yup.array().of(yup.string().min(24).max(24)),
  subcategories: yup.array().of(yup.string().min(24).max(24)),
  audience: yup.string(),
  prerequisites: yup.string(),
  objectives: yup.string(),
  modalities: yup.string(),
  dateStart: yup.date(),
  dateEnd: yup.date(),
  location: yup.string(),
  price: yup.number().typeError("Le prix de l'événement doit être indiqué en chiffres").min(0),
  intra: yup.bool(),
  inter: yup.bool(),
  certification: yup.bool(),
  display: yup.bool()
});

// prettier-ignore
const EditEventForm = ({
  updateEvent,
  categories,
  subcategories,
  redirectUrl,
  formation,
  getEventDetails,
  isLoading,
}) => {
  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  const { id } = useParams();

  // EVENT STATE
  const [localEvent, setLocalEvent] = useState(null);

  const [eventTitle, setEventTitle] = useState('');
  const [eventProgram, setEventProgram] = useState('');
  const [eventTarget, setEventTarget] = useState('');
  const [eventPrerequisites, setEventPrerequisites] = useState('');
  const [eventObjectives, setEventObjectives] = useState('');
  const [eventModalities, setEventModalities] = useState('');
  const [eventDateStart, setEventDateStart] = useState(undefined);
  const [eventDateEnd, setEventDateEnd] = useState(undefined);
  const [eventLocation, setEventLocation] = useState('');
  const [eventPrice, setEventPrice] = useState(undefined);
  const [isInter, setIsInter] = useState(undefined);
  const [isIntra, setIsIntra] = useState(undefined);
  const [isCertif, setIsCertif] = useState(undefined);
  const [isVisible, setIsVisible] = useState(undefined);

  // FORM STATE
  const [catList, setCatList] = useState([]);
  const [subcatList, setSubcatList] = useState([]);
  const [selectedCats, setSelectedCats] = useState([]);
  const [selectedSubcats, setSelectedSubCats] = useState([]);

  const [programInput, setProgramInput] = useState(null);
  const [objectivesInput, setObjectivesInput] = useState(null);

  // Hook form init
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(eventSchema),
  });

  // Get event details
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getEventDetails(id);
    }
    return () => {
      isMounted = false;
    }; // cleanup toggles value, if unmounted
  }, []);

  // Set event details
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLocalEvent(formation);

      setEventTitle(formation?.title);
      setEventProgram(formation?.program);

      // Set categories if not already selected
      formation?.categories?.forEach((cat) => {
        setSelectedCats((selected) => [...selected, cat._id]);
      });

      formation?.subcategories?.forEach((subcat) => {
        setSelectedSubCats((prevState) => [...prevState, subcat._id]);
      });

      setEventTarget(formation?.audience);
      setEventPrerequisites(formation?.prerequisites);
      setEventObjectives(formation?.objectives);
      setEventModalities(formation?.modalities);
      setEventDateStart(formation?.dateStart);
      setEventDateEnd(formation?.dateEnd);
      setEventLocation(formation?.location);
      setEventPrice(formation?.price);
      setIsInter(formation?.inter);
      setIsIntra(formation?.intra);
      setIsCertif(formation?.certification);
      setIsVisible(formation?.display);
    }
    return () => {
      isMounted = false;
      setSelectedCats([]);
      setSelectedSubCats([]);
    }; // cleanup toggles value, if unmounted
  }, [formation]);

  // set categories & subcategories select's lists
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setCatList(categories);
      setSubcatList(subcategories);
    }
    return () => {
      isMounted = false;
    }; // cleanup toggles value, if unmounted
  }, [categories, subcategories]);

  // Set values in form
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setValue('title', localEvent?.title);
      setValue('program', localEvent?.program);
      selectedCats?.forEach((cat) => {
        setValue('categories', [cat?._id]);
      });
      selectedSubcats?.forEach((subcat) => {
        setValue('subcategories', [subcat?._id]);
      });
      setValue('audience', localEvent?.audience);
      setValue('prerequisites', localEvent?.prerequisites);
      setValue('objectives', localEvent?.objectives);
      setValue('modalities', localEvent?.modalities);
      setValue('dateStart', localEvent?.dateStart);
      setValue('dateEnd', localEvent?.dateEnd);
      setValue('location', localEvent?.location);
      setValue('price', localEvent?.price);
      setValue('intra', localEvent?.intra);
      setValue('inter', localEvent?.inter);
      setValue('certification', localEvent?.certification);
      setValue('display', localEvent?.display);
    }
    return () => {
      isMounted = false;
    }; // cleanup toggles value, if unmounted
  }, [localEvent]);

  // SET VALUES FOR RICH TEXTFIELDS
  useEffect(() => {
    let isMounted = true;
    if (isMounted && eventProgram) {
      setProgramInput(eventProgram);
    }
    if (isMounted && eventObjectives) {
      setObjectivesInput(eventObjectives);
    }
    return () => {
      isMounted = false;
    };
  }, [eventProgram]);

  // FORM UTILS
  const handleChangeCategory = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCats(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeSubcategory = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSubCats(typeof value === 'string' ? value.split(',') : value);
  };

  const handleUpdateEvent = (data) => {
    const formData = {
      ...data,
      categories: selectedCats,
      subcategories: selectedSubcats,
      intra: isIntra,
      inter: isInter,
      certification: isCertif,
      display: isVisible,
    };
    console.log('submited data', formData);
    updateEvent(id, formData);
  };

  // prettier-ignore
  const CustomTextFieldInput = ({
    name,
    defaultValue,
    label,
    multiline,
    type
  }) => (
    <FormControl fullWidth>
      <Controller
        className="editEventForm-input"
        name={name}
        control={control}
        rules={{ required: false }}
        defaultValue={defaultValue ?? ''}
        render={({ field }) => (
          <TextField
            id={name}
            type={type || 'text'}
            label={label}
            multiline={multiline}
            errors={`errors.${name}`}
            helperText={`errors.${name}` && `${label} requis`}
            {...field}
          />
        )}
      />
    </FormControl>
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Container
          className="editEventForm"
          sx={{
            maxWidth: '600px',
            padding: '1rem 1rem 2rem',
          }}
        >
          <Typography variant="h5" textAlign="left" paddingBottom="1rem">
            Modifier l'événement
          </Typography>
          <form className="editEventForm-form" onSubmit={handleSubmit(handleUpdateEvent)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                textAlign: 'left',
              }}
            >
              {/* TITLE */}
              <CustomTextFieldInput
                name={'title'}
                defaultValue={eventTitle}
                required={true}
                multiline={false}
                label={'Nom de la formation'}
              />
              {/* PROGRAM */}
              {programInput && (
                <>
                  <Typography id="program-label" sx={{ color: '#888' }}>
                    Programme de la formation :
                  </Typography>

                  <FormControl fullWidth>
                    <Controller
                      className="editEventForm-input"
                      name={'program'}
                      control={control}
                      defaultValue={`${programInput}`}
                      rules={{ required: true }}
                      render={({ field }) => <RichTextfieldComponent {...field} />}
                    />
                  </FormControl>
                </>
              )}
              {/* Category */}
              {localEvent && selectedCats && (
                <FormControl fullWidth variant="outlined" error={!!errors.category}>
                  <>
                    <InputLabel id="category-label">Catégories</InputLabel>
                    <Select
                      id="categories"
                      onChange={handleChangeCategory}
                      inputProps={{ 'data-testid': 'category' }}
                      labelId="category-label"
                      label="Choisissez une catégorie"
                      value={selectedCats}
                      multiple
                    >
                      {catList &&
                        catList.length > 0 &&
                        catList.map((category) => (
                          <MenuItem key={category._id} value={category._id}>
                            {category.catTitle}
                          </MenuItem>
                        ))}
                    </Select>
                  </>

                  {errors.categories && <FormHelperText>La categorie est requise</FormHelperText>}
                </FormControl>
              )}

              {/* Sub Category */}
              {localEvent && selectedSubcats && (
                <FormControl fullWidth variant="outlined" error={!!errors.subcategory}>
                  <>
                    <InputLabel id="category-label">Sous-Catégories</InputLabel>
                    <Select
                      id="subcategories"
                      onChange={handleChangeSubcategory}
                      inputProps={{ 'data-testid': 'subcategory' }}
                      labelId="category-label"
                      label="Choisissez une sous-catégorie"
                      value={selectedSubcats}
                      multiple
                    >
                      {subcatList &&
                        subcatList.length > 0 &&
                        subcatList.map((subcategory) => (
                          <MenuItem key={subcategory._id} value={subcategory._id}>
                            {subcategory.subCatTitle}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                  {errors.subcategories && <FormHelperText>Sous categorie requise</FormHelperText>}
                </FormControl>
              )}
              {/* Audience */}
              <CustomTextFieldInput
                name={'audience'}
                defaultValue={eventTarget}
                required={true}
                multiline={true}
                label={'Cible de la formation'}
              />
              {/* Pre requisites */}
              <CustomTextFieldInput
                name={'prerequisites'}
                defaultValue={eventPrerequisites}
                required={true}
                multiline={true}
                label={'Pré-requis'}
              />
              {/* OBJECTIVES */}
              {objectivesInput && (
                <>
                  <Typography id="objectives-label" sx={{ color: '#888' }}>
                    Objectifs de la formation :
                  </Typography>
                  <FormControl fullWidth>
                    <Controller
                      className="editEventForm-input"
                      name={'objectives'}
                      control={control}
                      defaultValue={`${objectivesInput}`}
                      label={'Objectifs de la formation'}
                      rules={{ required: true }}
                      render={({ field }) => <RichTextfieldComponent {...field} />}
                    />
                  </FormControl>
                </>
              )}
              {/* MODALITIES */}
              <>
                <Typography id="Modalities-label" sx={{ color: '#888' }}>
                  Modalités pédagogiques :
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    className="editEventForm-input"
                    name={'modalities'}
                    control={control}
                    defaultValue={`${eventModalities}`}
                    label={'Modalités pédagogiques :'}
                    rules={{ required: true }}
                    render={({ field }) => <RichTextfieldComponent {...field} />}
                  />
                </FormControl>
              </>

              {/* <CustomTextFieldInput
                name={'modalities'}
                defaultValue={eventModalities}
                required={true}
                multiline={true}
                label={'Modalités pédagogiques'}
              /> */}
              {/* DATESTART */}
              <LocalizationProvider dateAdapter={AdapterDayjs} locale={frLocale}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Controller
                        className="editEventForm-input"
                        name="dateStart"
                        control={control}
                        defaultValue={eventDateStart}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <DatePicker
                            id="dateStart"
                            label="Date de début"
                            errors={errors.dateStart}
                            allowSameDateSelection
                            onAccept={(date) => {
                              setEventDateStart(date);
                            }}
                            renderInput={(props) => <TextField {...props} />}
                            {...field}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/* DATEEND */}
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Controller
                        className="editEventForm-input"
                        name="dateEnd"
                        control={control}
                        defaultValue={eventDateEnd}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <DatePicker
                            id="dateEnd"
                            label="Date de fin"
                            errors={errors.dateEnd}
                            allowSameDateSelection
                            onAccept={(date) => setEventDateEnd(date)}
                            renderInput={(props) => <TextField {...props} />}
                            {...field}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </LocalizationProvider>
              {/* LOCATION */}
              <CustomTextFieldInput
                name={'location'}
                defaultValue={eventLocation}
                required={true}
                multiline={false}
                label={'Lieu'}
              />
              {/* PRICE */}
              <CustomTextFieldInput
                name={'price'}
                defaultValue={eventPrice}
                required={true}
                multiline={false}
                label={'Prix'}
                type={'number'}
              />

              <Grid container spacing={2}>
                {/* INTRA */}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Controller
                      className="editEventForm-input"
                      name="intra"
                      control={control}
                      defaultValue={isIntra ?? false}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Switch checked={isIntra ?? false} onChange={(e) => setIsIntra(e.target.checked)} />}
                          label="Intra-entreprise"
                          errors={errors.intra}
                          {...field}
                        />
                      )}
                    />
                    {errors.intra && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
                  </FormControl>
                </Grid>
                {/* INTER */}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Controller
                      className="editEventForm-input"
                      name="inter"
                      control={control}
                      defaultValue={isInter ?? false}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Switch checked={isInter ?? false} onChange={(e) => setIsInter(e.target.checked)} />}
                          label="Inter-entreprise"
                          errors={errors.inter}
                          {...field}
                        />
                      )}
                    />
                    {errors.inter && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
                  </FormControl>
                </Grid>
                {/* CERTIFICATION */}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Controller
                      className="editEventForm-input"
                      name="certification"
                      control={control}
                      defaultValue={isCertif ?? false}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch checked={isCertif ?? false} onChange={(e) => setIsCertif(e.target.checked)} />
                          }
                          label="Certifiante"
                          errors={errors.certification}
                          {...field}
                        />
                      )}
                    />
                    {errors.certification && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
                  </FormControl>
                </Grid>
                {/* DISPLAY */}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Controller
                      className="editEventForm-input"
                      name="display"
                      control={control}
                      defaultValue={isVisible ?? false}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isVisible ?? false}
                              type="checkbox"
                              onChange={(e) => setIsVisible(e.target.checked)}
                            />
                          }
                          label="Visible"
                          errors={errors.display}
                          {...field}
                        />
                      )}
                    />
                    {errors.display && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>
              {/* SUBMIT BUTTON */}
              <Button type="submit" variant="contained" className="editEventForm-btn">
                Enregistrer les modifications
              </Button>
            </Box>
          </form>
        </Container>
      )}
    </>
  );
};

EditEventForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,

  // COMPONENT PROPS
  redirectUrl: PropTypes.string,
  updateEvent: PropTypes.func.isRequired,

  categories: PropTypes.array.isRequired,
  subcategories: PropTypes.array.isRequired,
  getEventDetails: PropTypes.func.isRequired,
  formation: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    program: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        catTitle: PropTypes.string
      })
    ),
    subcategories: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        catTitle: PropTypes.string
      })
    ),
    audience: PropTypes.string,
    prerequisites: PropTypes.string,
    objectives: PropTypes.string,
    modalities: PropTypes.string,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    location: PropTypes.string,
    price: PropTypes.number,
    intra: PropTypes.bool,
    inter: PropTypes.bool,
    certification: PropTypes.bool,
    display: PropTypes.bool
  }),

  // CUSTOM INPUT PROPS
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string
};

EditEventForm.defaultProps = {
  name: '',
  defaultValue: '',
  required: false,
  label: '',
  multiline: false,
  type: 'text',

  formation: PropTypes.shape({
    _id: '',
    title: '',
    program: '',
    audience: '',
    prerequisites: '',
    objectives: '',
    modalities: '',
    dateStart: '',
    dateEnd: '',
    location: '',
    price: null,
    intra: false,
    inter: false,
    certification: false,
    display: false
  })
};

export default EditEventForm;
