/* eslint-disable brace-style */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';

// Actions
// prettier-ignore
import {
  CREATE_EVENT,
  GET_EVENTS_LIST,
  setEventsList,
  getEventsList,
  DELETE_EVENT,
  UPDATE_EVENT,
  GET_EVENT_DETAILS,
  setEventDetails,
  getEventDetails,
} from '../actions/events';

import { startLoading, stopLoading } from '../actions/loading';
import { openSnackbar } from '../actions/snackbar';
import { redirectTo } from '../actions/redirect';
import { sendNewEventDetails } from '../actions/email';

const eventsMiddleware = (store) => (next) => (action) => {
  const tokenAuth = localStorage.getItem('token_auth');

  switch (action.type) {
    /// ///////////////////////////////////////////////////
    // GET & SET ALL EVENTS
    /// ///////////////////////////////////////////////////
    case GET_EVENTS_LIST: {
      store.dispatch(startLoading());

      axios
        .get(`${process.env.REACT_APP_API_URL}/events`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${tokenAuth}`,
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data.results > 0) {
            const { events } = response.data;
            store.dispatch(setEventsList(events));
          }
        })
        .catch((err) => {
          // console.trace(err);
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data?.message || 'Une erreur est survenue lors du chargement des événements'}`,
              severity: `${err.response?.data?.status || 'error'}`,
            })
          );
        })
        .finally(() => {
          store.dispatch(stopLoading());
        });
      break;
    }

    /// ///////////////////////////////////////////////////
    // CREATE EVENT
    /// ///////////////////////////////////////////////////
    case CREATE_EVENT: {
      store.dispatch(startLoading());
      axios
        .post(`${process.env.REACT_APP_API_URL}/events`, action.payload, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${tokenAuth}`,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            store.dispatch(
              openSnackbar({
                message: 'Événement ajouté, un email récapitulatif vous a été envoyé',
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(sendNewEventDetails(response?.data?.event));
            store.dispatch(getEventsList());
            store.dispatch(redirectTo(`/event/${response?.data?.event._id}/2`));
          }
        })
        .catch((err) => {
          console.trace(err);
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data?.message || "Une erreur est survenue lors de la création de l'événement"}`,
              severity: `${err.response?.data?.status || 'error'}`,
            })
          );
        })
        .finally(() => {
          store.dispatch(redirectTo(null));
          store.dispatch(stopLoading());
        });
      break;
    }

    /// ///////////////////////////////////////////////////
    // UPDATE EVENT
    /// ///////////////////////////////////////////////////
    case UPDATE_EVENT: {
      store.dispatch(startLoading());
      axios
        .patch(`${process.env.REACT_APP_API_URL}/events/${action.id}`, action.payload, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${tokenAuth}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Événement mis à jour'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(getEventDetails(response.data.data._id));
            store.dispatch(redirectTo(`/event/${response.data.data._id}/2`));
          }
        })
        .catch((err) => {
          if (err.response?.status === 304) {
            store.dispatch(
              openSnackbar({
                message: "L'événement n'a pas pu être sauvegardé",
                severity: 'error',
              })
            );
          } else {
            store.dispatch(
              openSnackbar({
                message: `${err.response?.data?.message || 'Une erreur est survenue'}`,
                severity: `${err.response?.data?.status || 'error'}`,
              })
            );
          }
        })
        .finally(() => {
          store.dispatch(redirectTo(null));
          store.dispatch(stopLoading());
        });
      break;
    }

    /// ///////////////////////////////////////////////////
    // DELETE EVENT
    /// ///////////////////////////////////////////////////
    case DELETE_EVENT: {
      store.dispatch(startLoading());
      axios
        .delete(`${process.env.REACT_APP_API_URL}/events/${action.id}`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${tokenAuth}`,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Événement supprimé'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(getEventsList());
            store.dispatch(redirectTo('/panel'));
          }
        })
        .catch((err) => {
          // console.trace(err);
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data?.message || 'Une erreur est survenue'}`,
              severity: `${err.response?.data?.status || 'error'}`,
            })
          );
        })
        .finally(() => {
          store.dispatch(redirectTo(null));
          store.dispatch(stopLoading());
        });
      console.log(store.dispatch, action);
      break;
    }

    /// ///////////////////////////////////////////////////
    // GET EVENT DETAILS
    /// ///////////////////////////////////////////////////
    case GET_EVENT_DETAILS: {
      store.dispatch(startLoading());
      axios
        .get(`${process.env.REACT_APP_API_URL}/events/${action.id}`)
        .then((response) => {
          if (response.status === 200) {
            store.dispatch(setEventDetails(response.data.data));
          }
        })
        .catch((err) => {
          // console.error(err);
          store.dispatch(
            openSnackbar({
              message: `${err?.response?.data?.message || 'Une erreur est survenue'}`,
              severity: 'error',
            })
          );
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    default:
      next(action);
  }
};

export default eventsMiddleware;
