import { connect } from 'react-redux';
import EventActionMenu from '../components/Utils/UIComponents/EventActionsMenu';

import { openModal } from '../actions/modal';

const mapStateToProps = (state) => ({
  isModalOpen: state.modal.isModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (payload) => dispatch(openModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventActionMenu);
