import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, useLocation } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import theme from '../../styles/theme';

// Components
import Header from '../../containers/navContainer';
import Snackbar from '../../containers/snackbarContainer';
import Logout from '../../containers/logoutContainer';
import PanelPage from '../../containers/panelContainer';
import NewEventForm from '../../containers/createEventFormContainer';
import NewAtelierForm from '../../containers/createAtelierFormContainer';
import EditEventForm from '../../containers/EditEventFormContainer';
import EditAtelierForm from '../../containers/editAtelierFormContainer';

import ForgotPwdForm from '../../containers/forgotPwdFormContainer';
import ResetPwdForm from '../../containers/resetPwdFormContainer';

import DBConnectionComponent from '../Utils/UIComponents/DBConnection';
import Loading from '../Utils/UIComponents/Loading';

// Pages
import FormaCoachPage from '../../containers/formacoachPageContainer';
import LoginPage from '../../pages/LoginPage';
import EventDetailsPage from '../../containers/eventDetailsPageContainer';
import AtelierDetailsPage from '../../containers/atelierDetailsPageContainer';

import './styles.scss';

const BOComponent = ({
  checkLogged,
  isAdminLogged,
  isLoading,
  getEventsList,
  getAteliersList,
  getCategoriesList,
  getSubcategorieslist,
  isScreenBig,
  setScreenBig,
  openSnackbar,
}) => {
  const location = useLocation();

  useEffect(() => {
    checkLogged();
  }, [location]);

  useEffect(() => {
    if (isAdminLogged) {
      getEventsList();
      getAteliersList();
    }
  }, [isAdminLogged]);

  useEffect(() => {
    getSubcategorieslist();
    getCategoriesList();
  }, []);

  const checkWindowDimensions = () => {
    const deviceWidth = window.innerWidth;
    const deviceHeight = window.innerHeight;
    if (deviceWidth < 800 || deviceWidth < deviceHeight) {
      setScreenBig(false);
      // eslint-disable-next-line brace-style
    } else {
      setScreenBig(true);
    }
  };

  useEffect(() => {
    checkWindowDimensions();
    window.addEventListener('resize', checkWindowDimensions);
    return () => window.removeEventListener('resize', checkWindowDimensions);
  }, []);

  useEffect(() => {
    checkWindowDimensions();
  }, [isScreenBig]);

  return (
    <ThemeProvider theme={theme}>
      <div className="Backoffice">
        <Header />
        {isLoading && <Loading />}
        <Routes>
          <Route path="/" exact element={<FormaCoachPage />} />
          <Route path="/login" exact element={<LoginPage />} />
          <Route path="/logout" exact element={<Logout />} />
          <Route path="/forgotpwd" exact element={<ForgotPwdForm />} />
          <Route path="/resetpwd/:id/:token" exact element={<ResetPwdForm />} />

          {isAdminLogged && (
            <>
              {/* SECURE ROUTES */}
              <Route path="/panel" exact element={<PanelPage />} />

              {/* ATELIERS */}
              <Route path="/atelier/new" exact element={<NewAtelierForm />} />
              <Route path="/atelier/:id/:index" exact element={<AtelierDetailsPage />} />
              <Route path="/atelier/edit/:id" exact element={<EditAtelierForm />} />

              {/* EVENTS */}
              <Route path="/event/new" exact element={<NewEventForm />} />
              <Route path="/event/edit/:id" exact element={<EditEventForm />} />
              <Route path="/event/:id/:index" exact element={<EventDetailsPage />} />
            </>
          )}

          {/* 404 */}
          <Route element={<FormaCoachPage />} />
        </Routes>
        {isAdminLogged && <DBConnectionComponent openSnackbar={openSnackbar} />}
        <Snackbar />
      </div>
    </ThemeProvider>
  );
};

// PropTypes
BOComponent.propTypes = {
  checkLogged: PropTypes.func.isRequired,
  getEventsList: PropTypes.func.isRequired,
  getAteliersList: PropTypes.func.isRequired,
  isAdminLogged: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSubcategorieslist: PropTypes.func.isRequired,
  getCategoriesList: PropTypes.func.isRequired,
  isScreenBig: PropTypes.bool.isRequired,
  setScreenBig: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
};

export default BOComponent;
