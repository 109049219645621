import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Backoffice from './containers/backofficeContainer';
import './styles/index.scss';

import configureStore from './store';

const store = configureStore();

const renderElement = (
  <Provider store={store}>
    <Router basename="/">
      <Backoffice />
    </Router>
  </Provider>
);

const renderTarget = document.getElementById('root');

ReactDOM.render(renderElement, renderTarget);
