import { SET_DATE_RANGE } from '../actions/calendar';

export const initialState = {
  selectedDateRange: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_DATE_RANGE:
      return {
        ...state,
        selectedDateRange: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
