import { SET_EVENTS_LIST, SET_EVENT_DETAILS } from '../actions/events';
import { SET_CATEGORIES_LIST, SET_SUBCATEGORIES_LIST } from '../actions/categories';

export const initialState = {
  eventsList: [],
  categories: [],
  subcategories: [],
  event: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_EVENTS_LIST:
      return {
        ...state,
        eventsList: action.payload,
      };
    case SET_EVENT_DETAILS:
      return {
        ...state,
        event: { ...action.payload },
      };
    case SET_CATEGORIES_LIST:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_SUBCATEGORIES_LIST:
      return {
        ...state,
        subcategories: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
