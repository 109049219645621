/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

/// ////////////////////////////////////// MUI //////////////////////////////////////
// prettier-ignore
import {
  Box,
  Select,
  FormHelperText,
  IconButton,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

// prettier-ignore
import {
  AddCircle,
} from '@mui/icons-material';

// MUI STYLE
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // prettier-ignore
      maxHeight: ((ITEM_HEIGHT * 4.5) + ITEM_PADDING_TOP),
      width: 250,
    },
  },
};

/// ////////////////////////////////////// MUI //////////////////////////////////////
const addSubcatSchema = yup.object().shape({
  subcategorieslist: yup.array().of(yup.string()),
});

// prettier-ignore
const AddSubcatToCatComponent = ({
  updateCategorySubcategoriesList,
  subcategories,
  catId,
}) => {
  const [selectedItemsId, setSelectedItemsId] = useState([]);

  // Hook form init
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(addSubcatSchema),
  });

  // useEffect(() => {
  //   console.log(errors);
  // }, [errors]);

  const handleAddSubcatToCat = () => {
    updateCategorySubcategoriesList({ catId, selectedItemsId });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedItemsId(value);
  };

  return (
    <Box className="addSubcatToCat" sx={{ minWidth: '100%' }}>
      <form
        className="addSubcatToCat-form"
        data-testid="addSubcatToCat-form"
        onSubmit={handleSubmit(handleAddSubcatToCat)}
      >
        {/* Subcategories list select */}
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            textAlign: 'start',
          }}
        >
          <FormControl fullWidth variant="outlined" error={!!errors.subcategory}>
            <Controller
              className="addSubcatToCat-form--input"
              name="subcategorieslist"
              control={control}
              defaultValue={[]}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <InputLabel id="subcategory-label">Sous-catégories :</InputLabel>
                  <Select
                    {...field}
                    labelId="subcategory-label"
                    label="Choisissez une ou plusieurs sous catégorie"
                    multiple
                    displayEmpty
                    value={selectedItemsId}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                  >
                    {subcategories &&
                      subcategories
                        .filter((subcat) => !subcat.categories.find((category) => category._id === catId))
                        .map((subcat) => (
                          <MenuItem key={subcat._id} value={subcat._id}>
                            {subcat.subCatTitle}
                          </MenuItem>
                        ))}
                  </Select>
                </>
              )}
            />
            {errors.subcategorieslist && <FormHelperText>Sous categorie requise</FormHelperText>}
          </FormControl>
          <IconButton
            type="submit"
            data-testid="submitBtn"
            aria-label="add subcategory"
            className="addSubcatToCat-form--btn"
            onClick={() => handleSubmit(handleAddSubcatToCat)}
            sx={{ paddingBottom: '2rem' }}
          >
            <AddCircle fontSize="large" color="primary" />
          </IconButton>
        </Box>
      </form>
    </Box>
  );
};

AddSubcatToCatComponent.propTypes = {
  catId: PropTypes.string.isRequired,
  updateCategorySubcategoriesList: PropTypes.func.isRequired,
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      subCatTitle: PropTypes.string,
      categories: PropTypes.array,
    })
  ),
};

export default AddSubcatToCatComponent;
