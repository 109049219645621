import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1d70b7',
    },
    seconday: {
      main: '#951b81',
    },
    darkBlue: {
      main: '#25275e',
    },
  },
  typography: {
    h2: {
      fontFamily: "'Jost', sans-serif",
      fontSize: 22,
      fontWeight: '900',
    },
    h3: {
      fontFamily: "'Jost', sans-serif",
      fontSize: 20,
      fontWeight: '700',
    },
    h4: {
      fontFamily: "'Jost', sans-serif",
      fontSize: 18,
      fontWeight: '500',
    },
  },
});

export default theme;
