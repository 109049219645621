/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// IMAGES

// COMPONENTS
import Loading from '../Loading';

import './styles.scss';

const ImgComponent = ({ url }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setImgUrl(url);
    }

    return () => {
      isMounted = false;
    };
  });

  return (
    <div className="imgComponent">
      {!imgLoaded && <Loading />}
      <img
        // Don't know why but '/imgs/categories/formateurs-2-600.jpg' won't load anywhere event if it exists
        // Requirering the image seems to fix the problem
        // src={require(`../../../../assets/imgs/categories/${url}`)}
        src={`/img/categories/${imgUrl}`}
        alt={`${imgUrl} image`}
        onLoad={() => setImgLoaded(true)}
        // If it fails, we try to load public images
        onError={(e) => {
          e.target.src = `/imgs/categories/${url}`;
        }}
      />
    </div>
  );
};

ImgComponent.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ImgComponent;
