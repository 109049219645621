import { connect } from 'react-redux';
import EditEventForm from '../components/EditEventForm';

import { updateEvent, getEventDetails } from '../actions/events';

const mapStateToProps = (state) => ({
  isAdminLogged: state.admin.isAdminLogged,
  categories: state.events.categories,
  subcategories: state.events.subcategories,
  redirectUrl: state.redirect.redirectUrl,
  formation: state.events.event,
  isLoading: state.loading.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateEvent: (id, payload) => dispatch(updateEvent(id, payload)),
  getEventDetails: (id) => dispatch(getEventDetails(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEventForm);
