import { connect } from 'react-redux';
import DetachBtnComponent from '../components/Utils/UIComponents/DetachBtnComponent';

// prettier-ignore
import {
  removeSubcategory
} from '../actions/categories';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  removeSubcategory: (payload) => dispatch(removeSubcategory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetachBtnComponent);
