/* eslint-disable brace-style */
/* eslint-disable no-underscore-dangle */
// prettier-ignore
import {
  CREATE_ATELIER,
  DELETE_ATELIER,
  getAtelierDetails,
  getAteliersList,
  GET_ATELIERS_LIST,
  GET_ATELIER_DETAILS,
  setAtelierDetails,
  setAteliersList,
  UPDATE_ATELIER
} from '../actions/ateliers';

import { startLoading, stopLoading } from '../actions/loading';
import { openSnackbar } from '../actions/snackbar';
import { redirectTo } from '../actions/redirect';

import instance from './axiosInstance';

const ateliersMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case CREATE_ATELIER: {
      // !!!!! DONT FORGET STORE.DISPATCH MODAFUKA !!!!!
      console.log('create atelier middleware', action.payload);
      store.dispatch(startLoading());
      instance
        .post(`${process.env.REACT_APP_API_URL}/ateliers`, action.payload)
        .then((response) => {
          if (response.status === 201) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Atelier ajouté'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(getAteliersList());
            store.dispatch(redirectTo(`/atelier/${response.data.newAtelier._id}/2`));
          }
        })
        .catch((err) => {
          console.trace(err);
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data?.message || "Une erreur est survenue lors de la création de l'atelier"}`,
              severity: `${err.response?.data?.status || 'error'}`,
            })
          );
        })
        .finally(() => {
          store.dispatch(redirectTo(null));
          store.dispatch(stopLoading());
        });
      break;
    }

    // GET LIST
    case GET_ATELIERS_LIST: {
      store.dispatch(startLoading());
      instance
        .get('/ateliers')
        .then((response) => {
          if (response.status === 200 && response.data.results > 0) {
            const { ateliers } = response.data;
            store.dispatch(setAteliersList(ateliers));
          }
        })
        .catch((err) => {
          // console.trace(err);
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data?.message || 'Une erreur est survenue lors du chargement des ateliers'}`,
              severity: `${err.response?.data?.status || 'error'}`,
            })
          );
        })
        .finally(() => {
          store.dispatch(stopLoading());
        });
      break;
    }

    /// ///////////////////////////////////////////////////
    // UPDATE ATELIER
    /// ///////////////////////////////////////////////////
    case UPDATE_ATELIER: {
      store.dispatch(startLoading());
      instance
        .patch(`/ateliers/${action.id}`, action.payload)
        .then((response) => {
          if (response.status === 200) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Atelier mis à jour'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(getAtelierDetails(response.data.data._id));
            store.dispatch(redirectTo(`/atelier/${response.data.data._id}/2`));
          }
        })
        .catch((err) => {
          if (err.response?.status === 304) {
            store.dispatch(
              openSnackbar({
                message: "L'atelier n'a pas pu être sauvegardé",
                severity: 'error',
              })
            );
          } else {
            store.dispatch(
              openSnackbar({
                message: `${err.response?.data?.message || 'Une erreur est survenue'}`,
                severity: `${err.response?.data?.status || 'error'}`,
              })
            );
          }
        })
        .finally(() => {
          store.dispatch(redirectTo(null));
          store.dispatch(stopLoading());
        });
      break;
    }

    /// ///////////////////////////////////////////////////
    // DELETE ATELIER
    /// ///////////////////////////////////////////////////
    case DELETE_ATELIER: {
      store.dispatch(startLoading());
      instance
        .delete(`/ateliers/${action.id}`)
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Atelier supprimé'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(getAteliersList());
            store.dispatch(redirectTo('/panel'));
          }
        })
        .catch((err) => {
          // console.trace(err);
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data?.message || 'Une erreur est survenue'}`,
              severity: `${err.response?.data?.status || 'error'}`,
            })
          );
        })
        .finally(() => {
          store.dispatch(redirectTo(null));
          store.dispatch(stopLoading());
        });
      console.log(store.dispatch, action);
      break;
    }

    /// ///////////////////////////////////////////////////
    // GET ATELIER DETAILS
    /// ///////////////////////////////////////////////////
    case GET_ATELIER_DETAILS: {
      store.dispatch(startLoading());
      instance
        .get(`/ateliers/${action.id}`)
        .then((response) => {
          if (response.status === 200) {
            store.dispatch(setAtelierDetails(response.data?.atelierFound));
          }
        })
        .catch((err) => {
          // console.error(err);
          store.dispatch(
            openSnackbar({
              message: `${err?.response?.data?.message || 'Une erreur est survenue'}`,
              severity: 'error',
            })
          );
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    default:
      next(action);
  }
};

export default ateliersMiddleware;
