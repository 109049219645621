import { connect } from 'react-redux';
import EditAtelierForm from '../components/EditAtelierForm';

import { updateAtelier, getAtelierDetails } from '../actions/ateliers';

const mapStateToProps = (state) => ({
  isAdminLogged: state.admin.isAdminLogged,
  categories: state.events.categories,
  subcategories: state.events.subcategories,
  redirectUrl: state.redirect.redirectUrl,
  atelier: state.ateliers.atelier,
  isLoading: state.loading.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateAtelier: (id, payload) => dispatch(updateAtelier(id, payload)),
  getAtelierDetails: (id) => dispatch(getAtelierDetails(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAtelierForm);
