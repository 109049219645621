import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';

import HomeCalendar from '../../containers/homeCalendarContainer';
import HandleCategoriesComponent from '../../components/CategoriesComponents/HandleCategoriesComponent';
import HomeEventsList from '../../components/HomeEventsList';

import './styles.scss';

// prettier-ignore
const FormaCoachPage = ({
  eventsList, ateliersList, setDateRange, isScreenBig, isAdminLogged
}) => (
    <>
      {isAdminLogged && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            alignItems: 'center',
            width: 'min(95%, 1200px)',
            marginInline: 'auto',
            overflowX: 'hidden',
          }}
          role="formaCoachPage"
        >
          <HomeCalendar />
          <Divider />
          <HandleCategoriesComponent />
          <Divider />
          <HomeEventsList
            eventsList={eventsList}
            ateliersList={ateliersList}
            setDateRange={setDateRange}
            isScreenBig={isScreenBig}
          />
        </Box>
      )}
    </>
);

FormaCoachPage.propTypes = {
  isAdminLogged: PropTypes.bool.isRequired,
  isScreenBig: PropTypes.bool.isRequired,

  // EVENTS
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string.isRequired,
      dateEnd: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),

  // ATELIERS
  ateliersList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),
  setDateRange: PropTypes.func.isRequired,
};

export default FormaCoachPage;
