export const SEND_RESET_PWD_MAIL = 'SEND_RESET_PWD_MAIL';
export const SEND_NEW_EVENT_DETAILS = 'SEND_NEW_EVENT_DETAILS';

export const sendNewEventDetails = (payload) => ({
  type: SEND_NEW_EVENT_DETAILS,
  payload,
});

export const sendResetPwdMail = (payload) => ({
  type: SEND_RESET_PWD_MAIL,
  payload,
});
