/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
// prettier-ignore
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Grid,
  Divider
} from '@mui/material';

// prettier-ignore
import {
  MoreVert,
  CheckCircle,
  Cancel,
  KeyboardArrowDown
} from '@mui/icons-material';
import './styles.scss';

import { Link } from 'react-router-dom';

// Hooks & Utils
import useFormatDate from '../../../customHooks/useFormatDate';

// Components
import EventActionsMenu from '../../../containers/EventActionMenuContainer';
import DangerouslyDisplayHTMLComponent from '../../Utils/UIComponents/DangerouslyDisplayHTMLComponent';
import ChipComponent from '../../Utils/UIComponents/ChipComponent';

// eslint-disable-next-line react/display-name
const EventComponent = ({ event, index }) => {
  const [navLink, setNavLink] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isProgramOpen, setIsProgramOpen] = useState(false);

  // prettier-ignore
  const {
    title,
    program,
    categories,
    subcategories,
    subcategory,
    dateStart,
    dateEnd,
    duration,
    price,
    intra,
    inter,
    certification,
    display,
    _id,
  } = event;

  const handleOpenSettings = () => {
    setIsOpen(!isOpen);
  };

  const handleDisplayProgram = () => {
    setIsProgramOpen(!isProgramOpen);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (
        !subcategory &&
        categories?.length > 0
      ) {
        setNavLink(`/event/${_id}/${index % 2 === 0 ? 2 : 1}`);
      }
      // prettier-ignore
      else {
        setNavLink(`/atelier/${_id}/${index % 2 === 0 ? 2 : 1}`);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [event]);

  // const handleClickOutside = (e) => {
  //   console.log('because of this');
  //   if (!ref?.current?.contains(e.target)) {
  //     setIsOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //     // setIsOpen(false);
  //   };
  // }, []);

  return (
    <Card className="eventCard" variant="outlined">
      {/* EDIT / DELETE MODAL */}
      <CardActions
        sx={{
          display: 'flex',
          alignItems: 'start',
          gap: '1rem',
          justifyContent: 'flex-end',
          height: 0,
        }}
      >
        {isOpen && <EventActionsMenu id={event?._id} subcategory={event?.subcategory?.subCatTitle} />}
        <IconButton
          // Classname is for testing
          className="eventCard-settings"
          aria-label="settings"
          onClick={handleOpenSettings}
          sx={{
            position: 'sticky',
            right: '1rem',
          }}
        >
          <MoreVert />
        </IconButton>
      </CardActions>
      {/* CONTENT */}
      <CardHeader
        sx={{ padding: '0 3rem 1rem' }}
        title={title}
        subheader={!duration ? `${useFormatDate(dateStart)} - ${useFormatDate(dateEnd)}` : `Atelier de ${duration}`}
      />
      <Divider />
      <CardContent
        sx={{
          textAlign: 'start',
        }}
      >
        {/* PROGRAM */}
        {/* <Typography variant="body2">{program}</Typography> */}
        <Box
          className="eventCard-shadowbox"
          onClick={handleDisplayProgram}
          sx={{
            maxHeight: `${!isProgramOpen ? '13rem' : '100%'}`,
          }}
        >
          <DangerouslyDisplayHTMLComponent content={program} />
        </Box>

        {/* DOWN ARROW */}
        <Box className={'eventCard-arrow'} onClick={handleDisplayProgram}>
          <KeyboardArrowDown
            className={`eventCard-arrow${isProgramOpen && '--hover'}`}
            color={'#FFF'}
            sx={{
              fontSize: '2rem',
              textAlign: 'center',
              transform: `rotate(${isProgramOpen ? '180' : '0'}deg)`,
              transition: 'all 200ms ease-in-out',
            }}
          />
        </Box>
        {/* BOOLEANS */}
        <Divider
          sx={{
            margin: '0 -1rem',
            width: '200%',
          }}
        />
        <Grid
          container
          spacing={2}
          sx={{
            paddingTop: '1rem',
            alignItems: 'center',
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body2" className="eventCard-grid--item">
              Intra : {intra ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="eventCard-grid--item">
              Inter : {inter ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="eventCard-grid--item">
              Certifiante : {certification ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className="eventCard-grid--item">
              Visible : {display ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
            </Typography>
          </Grid>
        </Grid>
        {/* CATEGORIES + SOUS CATEGORIES */}
        <Divider
          sx={{
            margin: '1rem -1rem',
            width: '200%',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            marginTop: '1rem',
            justifyContent: 'center',
            alignContent: 'flex-start',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              gap: '0.5rem',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                gap: '0.5rem',
              }}
            >
              <Typography>Catégories :</Typography>
              {categories?.length > 0 &&
                categories.map((category) => <ChipComponent key={category?._id} label={category?.catTitle} />)}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                gap: '0.5rem',
              }}
            >
              <Typography>Sous-catégories :</Typography>
              {subcategories?.length > 0 ? (
                subcategories.map((subcat) => <ChipComponent key={subcat?._id} label={subcat?.subCatTitle} />)
              ) : (
                <ChipComponent label={subcategory?.subCatTitle} />
              )}
            </Box>
            <Divider
              sx={{
                margin: '0 -1rem',
                width: '200%',
              }}
            />
          </Box>

          {/* PRIX INTER + INTRA */}
          <Typography
            variant="h6"
            sx={{
              flexGrow: 2,
              textAlign: 'start',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              alignItems: 'baseline',
            }}
          >
            {/* Display informed prices or default on demand */}
            {inter ? (
              <>
                Prix Inter :
                <Typography style={{ margin: '0 0.5rem', color: '#1d70b7' }}>
                  {price ? `${price} €` : 'Sur demande'}
                </Typography>
                {intra && (
                  <>
                    | Prix Intra :<Typography style={{ margin: '0 0.5rem', color: '#1d70b7' }}>Sur demande</Typography>
                  </>
                )}
              </>
            ) : (
              <>
                Prix :<Typography style={{ margin: '0 0.5rem', color: '#1d70b7' }}>Sur demande</Typography>
              </>
            )}
          </Typography>

          <Divider
            sx={{
              margin: '0 -1rem',
            }}
          />
          <Link to={navLink}>{'Voir plus'}</Link>
        </Box>
      </CardContent>
    </Card>
  );
};

EventComponent.propTypes = {
  event: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        catTitle: PropTypes.string,
        _id: PropTypes.string,
        subcategories: PropTypes.arrayOf(
          PropTypes.shape({
            subCatTitle: PropTypes.string,
            _id: PropTypes.string,
            categories: PropTypes.arrayOf(PropTypes.string),
          })
        ),
      })
    ),
    subcategories: PropTypes.arrayOf(
      PropTypes.shape({
        subCatTitle: PropTypes.string,
        _id: PropTypes.string,
        categories: PropTypes.arrayOf(
          PropTypes.shape({
            catTitle: PropTypes.string,
            _id: PropTypes.string,
            subcategories: PropTypes.arrayOf(PropTypes.string),
          })
        ),
      })
    ),
    subcategory: PropTypes.shape({
      subCatTitle: PropTypes.string,
      _id: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string),
    }),
    audience: PropTypes.string.isRequired,
    prerequisites: PropTypes.string.isRequired,
    objectives: PropTypes.string.isRequired,
    modalities: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    intra: PropTypes.bool.isRequired,
    inter: PropTypes.bool.isRequired,
    certification: PropTypes.bool.isRequired,
    display: PropTypes.bool.isRequired,

    // NOT REQUIRED
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    duration: PropTypes.string,
    price: PropTypes.number,
  }),
};

EventComponent.defaultProps = {
  dateStart: '',
  dateEnd: '',
  duration: '',
  price: null,
};

export default EventComponent;
