import { connect } from 'react-redux';
import LoginForm from '../components/LoginForm';

import { submitLogin } from '../actions/auth';

const mapStateToProps = (state) => ({
  isAdminLogged: state.admin.isAdminLogged,
  redirectUrl: state.redirect.redirectUrl,
});

const mapDispatchToProps = (dispatch) => ({
  submitLogin: (data) => dispatch(submitLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
