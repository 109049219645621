// React
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, NavLink, Navigate } from 'react-router-dom';

import './styles.scss';

// MUI
// prettier-ignore
import {
  Box,
  Grid,
  Typography,
  Button
} from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';

// Components & Hooks
import ModalComponent from '../../containers/modalContainer';
import DangerouslyDisplayHTMLComponent from '../../components/Utils/UIComponents/DangerouslyDisplayHTMLComponent';
import ChipComponent from '../../components/Utils/UIComponents/ChipComponent';
import Loading from '../../components/Utils/UIComponents/Loading';

// prettier-ignore
const AtelierDetailsPage = ({
  getAtelierDetails,
  atelier,
  redirectUrl,
  openModal,
  isModalOpen
}) => {
  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  const [event, setEvent] = useState(null);
  const [imgLoaded, setImgLoaded] = useState(false);
  // default img link is supposed to be the correct one
  const [imgLink, setImgLink] = useState(null);
  const { id, index } = useParams();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getAtelierDetails(id);
    }

    return () => { isMounted = false; };
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && event?.categories?.length > 0) {
      setImgLink(`${event?.categories[0]?.catTitle}/category-${event?.categories[0]?.catTitle}-${index || 2}-big.jpg`);
    }

    return () => {
      isMounted = false;
    };
  }, [event]);

  const {
    title,
    program,
    categories,
    subcategory,
    audience,
    prerequisites,
    objectives,
    modalities,
    duration,
    location,
    intra,
    inter,
    certification,
    display,
  } = atelier;

  useEffect(() => {
    setEvent(atelier);
  }, [atelier]);

  useEffect(() => {
    if (event?.categories?.length > 0) {
      const catLink = event?.categories[0]?.catTitle;
      setImgLink(`/img/categories/${catLink ?? 'formateurs'}/category-${catLink ?? 'formateurs'}-1-big.jpg`);
    }
  }, [event]);

  const handleDeleteAtelier = () => {
    openModal('DELETE_ATELIER');
  };

  return (
    <>
      {isModalOpen && <ModalComponent id={id} />}
      <Box
        className="atelierDetails"
        sx={{
          padding: '1em 0',
          width: 'min(95%, 1200px)',
          marginInline: 'auto',
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            textAlign: 'left',
          }}
        >
          <Grid item sm={6} xs={12}>
            <h6 className="atelierDetails-title">{title}</h6>
            {/* <h4 className="grid-item--title">Programme :</h4> */}
            <DangerouslyDisplayHTMLComponent content={program} />
          </Grid>
          <Grid item sm={6} xs={12}>
            {!imgLoaded && <Loading />}
            <img src={imgLink} alt={'atelier image'} onLoad={() => setImgLoaded(true)} />
          </Grid>
          {/* LEFT BLOCK */}
          <Grid item container sm={6} xs={12} spacing={3} alignContent={'start'}>
            <Grid item xs={6}>
              <h4 className="grid-item--title">Catégorie:</h4>
              {categories?.length > 0 ? (
                categories.map((category) => (
                  // eslint-disable-next-line no-underscore-dangle
                  <ChipComponent key={category?._id} label={category?.catTitle} />
                ))
              ) : (
                <Typography>Pas de catégorie</Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <h4 className="grid-item--title">Sous-catégorie:</h4>
              {subcategory?.subCatTitle ? (
                <ChipComponent label={subcategory?.subCatTitle} />
              ) : (
                <Typography>Pas de sous-catégorie</Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <h4 className="grid-item--title">Durée</h4>
              <h3>{duration}</h3>
            </Grid>
            <Grid item xs={6}>
              <h4 className="grid-item--title">Lieu:</h4>
              <h3>{location}</h3>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'inline-flex', gap: '1rem', alignItems: 'baseline' }}>
                <h4 className="grid-item--title">Prix: </h4>
                <h3>Sur demande</h3>
              </Box>
            </Grid>
            {/* PICTOS BLOCK */}
            <Grid item container spacing={3} alignItems={'start'}>
              <Grid item container xs={6} alignItems={'center'}>
                <h4>Intra-entreprise : </h4>
                {intra ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
              </Grid>
              <Grid item container xs={6} alignItems={'center'}>
                <h4>Inter-entreprise : </h4>
                {inter ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
              </Grid>
              <Grid item container xs={6} alignItems={'center'}>
                <h4>Certification : </h4>
                {certification ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
              </Grid>
              <Grid item container xs={6} alignItems={'center'}>
                <h4>Visible : </h4>
                {display ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
              </Grid>
            </Grid>
          </Grid>
          {/* RIGHT BLOCK */}
          <Grid item container sm={6} xs={12} direction="column" spacing={3}>
            <Grid item>
              <h4 className="grid-item--title">Public:</h4>
              <Typography>{audience}</Typography>
            </Grid>
            <Grid item>
              <h4 className="grid-item--title">Prérequis:</h4>
              <Typography>{prerequisites}</Typography>
            </Grid>
            <Grid item>
              <h4 className="grid-item--title">Objectifs:</h4>
              <DangerouslyDisplayHTMLComponent content={objectives} />
            </Grid>
            <Grid item>
              <h4 className="grid-item--title">Modalités:</h4>
              <DangerouslyDisplayHTMLComponent content={modalities} />
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            paddingTop: '2rem',
            display: 'flex',
            gap: '2rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <NavLink
            to={`/atelier/edit/${id}`}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button variant="contained">Modifier l'événement</Button>
          </NavLink>
          <Button variant="outlined" onClick={handleDeleteAtelier}>
            Supprimer l'événement
          </Button>
        </Box>
      </Box>
    </>
  );
};

AtelierDetailsPage.propTypes = {
  redirectUrl: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  getAtelierDetails: PropTypes.func.isRequired,
  atelier: PropTypes.shape({
    title: PropTypes.string,
    program: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        catTitle: PropTypes.string,
        _id: PropTypes.string,
        subcategories: PropTypes.arrayOf(
          PropTypes.shape({
            subCatTitle: PropTypes.string,
            _id: PropTypes.string,
            categories: PropTypes.arrayOf(PropTypes.string)
          })
        )
      })
    ),
    subcategory: PropTypes.shape({
      subCatTitle: PropTypes.string,
      _id: PropTypes.string
    }),
    audience: PropTypes.string,
    prerequisites: PropTypes.string,
    objectives: PropTypes.string,
    modalities: PropTypes.string,
    duration: PropTypes.string,
    location: PropTypes.string,
    intra: PropTypes.bool,
    inter: PropTypes.bool,
    certification: PropTypes.bool,
    display: PropTypes.bool
  })
};

AtelierDetailsPage.defaultProps = {
  atelier: PropTypes.shape({
    title: '',
    program: '',
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        catTitle: '',
        _id: '',
        subcategories: PropTypes.arrayOf(
          PropTypes.shape({
            subCatTitle: '',
            _id: '',
            categories: []
          })
        )
      })
    ),
    subcategory: PropTypes.shape({
      subCatTitle: '',
      _id: ''
    }),
    audience: '',
    prerequisites: '',
    objectives: '',
    modalities: '',
    duration: '',
    location: '',
    intra: false,
    inter: false,
    certification: false,
    display: false
  })
};

export default AtelierDetailsPage;
