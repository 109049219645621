import { connect } from 'react-redux';
import newAtelierForm from '../components/NewAtelierForm';

import { createAtelier } from '../actions/ateliers';

const mapStateToProps = (state) => ({
  categories: state.events.categories,
  subcategories: state.events.subcategories,
  redirectUrl: state.redirect.redirectUrl,
});

const mapDispatchToProps = (dispatch) => ({
  createAtelier: (payload) => dispatch(createAtelier(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(newAtelierForm);
