import { connect } from 'react-redux';
import NewEventForm from '../components/NewEventForm';

import { createEvent } from '../actions/events';

const mapStateToProps = (state) => ({
  categories: state.events.categories,
  subcategories: state.events.subcategories,
  redirectUrl: state.redirect.redirectUrl,
});

const mapDispatchToProps = (dispatch) => ({
  createEvent: (payload) => dispatch(createEvent(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewEventForm);
