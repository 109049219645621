export const GET_ATELIERS_LIST = 'GET_ATELIERS_LIST';
export const SET_ATELIERS_LIST = 'SET_ATELIERS_LIST';
export const CREATE_ATELIER = 'CREATE_ATELIER';
export const DELETE_ATELIER = 'DELETE_ATELIER';
export const UPDATE_ATELIER = 'UPDATE_ATELIER';
export const GET_ATELIER_DETAILS = 'GET_ATELIER_DETAILS';
export const SET_ATELIER_DETAILS = 'SET_ATELIER_DETAILS';

export const setAtelierDetails = (payload) => ({
  type: SET_ATELIER_DETAILS,
  payload,
});

export const getAtelierDetails = (id) => ({
  type: GET_ATELIER_DETAILS,
  id,
});

export const updateAtelier = (id, payload) => ({
  type: UPDATE_ATELIER,
  id,
  payload,
});

export const deleteAtelier = (id) => ({
  type: DELETE_ATELIER,
  id,
});

export const createAtelier = (payload) => ({
  type: CREATE_ATELIER,
  payload,
});

export const setAteliersList = (payload) => ({
  type: SET_ATELIERS_LIST,
  payload,
});

export const getAteliersList = () => ({
  type: GET_ATELIERS_LIST,
});
