import { startLoading, stopLoading } from '../actions/loading';
import { openSnackbar } from '../actions/snackbar';
import {
  GET_CATEGORIES_LIST,
  GET_SUBCATEGORIES_LIST,
  UPDATE_CATEGORY_SUBCATEGORIES_LIST,
  CREATE_SUBCATEGORY,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  getCategoriesList,
  getSubcategorieslist,
  setCategoriesList,
  setSubcategorieslist,
  REMOVE_SUBCATEGORY,
  DELETE_SUBCATEGORY,
} from '../actions/categories';

import instance from './axiosInstance';

const categoriesMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    /// ////////////////////////////////////////////////////////////////
    // CATEGORIES
    /// ////////////////////////////////////////////////////////////////
    case GET_CATEGORIES_LIST: {
      store.dispatch(startLoading());
      // !!!!! DONT FORGET STORE.DISPATCH MODAFUKA !!!!!
      instance
        .get('/categories')
        .then((response) => {
          if (response.status === 200) {
            store.dispatch(setCategoriesList(response.data.categories));
          }
        })
        .catch((err) => {
          // console.trace(err);
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data.message || 'Une erreur est survenue lors du chargement des catégories'}`,
              severity: `${err.response?.data.status || 'error'}`,
            })
          );
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    case CREATE_CATEGORY: {
      // console.log(action.payload);
      store.dispatch(startLoading());
      instance
        .post('/categories', action.payload)
        .then((response) => {
          // console.log(response);
          if (response.status === 201) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Catégorie ajoutée'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(getCategoriesList());
          }
        })
        .catch((err) => {
          // console.trace(err);
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data.message || 'Une erreur est survenue lors de la création de la catégorie'}`,
              severity: `${err.response?.data.status || 'error'}`,
            })
          );
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    case DELETE_CATEGORY: {
      // console.log('deleting category', action);
      instance
        .delete(`/categories/${action.id}`)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Catégorie supprimée'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(getCategoriesList());
          }
        })
        .catch((err) => {
          console.log(err);
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data.message || 'La catégorie possède des sous-catégories'}`,
              severity: `${err.response?.data.status || 'error'}`,
            })
          );
        });
      break;
    }
    /// ////////////////////////////////////////////////////////////////
    // SUB CATEGORIES
    /// ////////////////////////////////////////////////////////////////
    case GET_SUBCATEGORIES_LIST: {
      // !!!!! DONT FORGET STORE.DISPATCH MODAFUKA !!!!!
      store.dispatch(startLoading());
      // !!!!! DONT FORGET STORE.DISPATCH MODAFUKA !!!!!
      instance
        .get('/subcats')
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            store.dispatch(setSubcategorieslist(response.data.subCats));
          }
        })
        .catch((err) => {
          // console.trace(err);
          store.dispatch(
            openSnackbar({
              message: `${
                err.response?.data.message || 'Une erreur est survenue lors du chargement des sous-catégories'
              }`,
              severity: `${err.response?.data.status || 'error'}`,
            })
          );
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    case CREATE_SUBCATEGORY: {
      store.dispatch(startLoading());
      instance
        .post('/subcats', action.payload)
        .then((response) => {
          // console.log(response);
          if (response.status === 201) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Sous-catégorie ajoutée'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(setSubcategorieslist(response.data.subCats));
          }
        })
        .catch((err) => {
          // console.trace(err);
          store.dispatch(
            openSnackbar({
              message: `${
                err.response?.data.message || 'Une erreur est survenue lors de la création de la sous-catégorie'
              }`,
              severity: `${err.response?.data.status || 'error'}`,
            })
          );
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    case REMOVE_SUBCATEGORY: {
      instance
        // .patch('/subcats/detach', { catId, subcatId: '627392bdfa51bf764738b7f5' })
        .patch('/subcats/detach', action.payload)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Sous-catégorie ajoutée'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(getCategoriesList());
          }
        })
        .catch((err) => {
          console.log(err);
        });
      break;
    }
    case DELETE_SUBCATEGORY: {
      // console.log('deleting subcategory', action);
      instance
        .delete(`/subcats/${action.id}`)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Sous-catégorie supprimée'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(getSubcategorieslist());
          }
        })
        .catch((err) => {
          console.log(err);
        });
      break;
    }
    /// ////////////////////////////////////////////////////////////////
    // CATEGORIES + SUBCATEGORIES LISTS
    /// ////////////////////////////////////////////////////////////////
    case UPDATE_CATEGORY_SUBCATEGORIES_LIST: {
      console.log('payload is :', action.payload);
      store.dispatch(startLoading());
      instance
        .patch(`/categories/${action.payload.catId}`, action.payload)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Catégorie mise à jour'}`,
                severity: `${response.data.status || 'success'}`,
              })
            );
            store.dispatch(getCategoriesList());
            // store.dispatch(setSubcategorieslist(response?.data?.updatedCategory?.subcategories));
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    default:
      next(action);
  }
};

export default categoriesMiddleware;
