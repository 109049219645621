/* eslint-disable camelcase */
import axios from 'axios';

// prettier-ignore
import {
  setAdminLogged,
  SUBMIT_LOGIN,
  CHECK_LOGGED,
  LOGOUT,
  logoutUser,
  RESET_PASSWORD
} from '../actions/auth';
import { startLoading, stopLoading } from '../actions/loading';
import { redirectTo } from '../actions/redirect';
import { openSnackbar } from '../actions/snackbar';

import instance from './axiosInstance';

const authMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case LOGOUT: {
      store.dispatch(startLoading());

      // Remove item from localstorage
      localStorage.removeItem('token_auth');

      // Check headers content in back, if no token found, we logout user
      // Not mandatory but add a security layer
      axios
        .get(`${process.env.REACT_APP_API_URL}/auth/logout`, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200) {
            store.dispatch(logoutUser());

            store.dispatch(
              openSnackbar({
                message: `${'Vous êtes bien déconnecté'}`,
                severity: `${'success'}`,
              })
            );

            store.dispatch(redirectTo(null));
          }
          // 401 = token found => Token not deleted when logged out
          else if (response.status === 403) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || 'Une erreur est survenue lors de la déconnection'}`,
                severity: `${response.data.status || 'error'}`,
              })
            );
          }
        })
        .catch((err) => {
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data?.message || 'Une erreur est survenue'}`,
              severity: `${err.response?.data?.status || 'error'}`,
            })
          );
        })
        .finally(() => {
          store.dispatch(stopLoading());
        });
      break;
    }
    // CKECK USER TOKEN
    case CHECK_LOGGED: {
      store.dispatch(startLoading());
      const token = localStorage.getItem('token_auth');
      axios
        .get(`${process.env.REACT_APP_API_URL}/auth/checkToken`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            store.dispatch(
              setAdminLogged({
                id: response.data.user.id,
                email: response.data.user.email,
                username: response.data.user.username,
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          localStorage.removeItem('token_auth');
          store.dispatch(logoutUser());
          store.dispatch(
            openSnackbar({
              message: 'Session expirée, veuillez vous reconnecter',
              severity: 'error',
            })
          );
        })
        .finally(() => {
          store.dispatch(stopLoading());
        });
      break;
    }
    // SUBMIT LOGIN FORM THEN SET USER LOGGED
    case SUBMIT_LOGIN: {
      store.dispatch(startLoading());
      instance
        .post('/auth/login', {
          email: action.data.email,
          password: action.data.password,
        })
        .then((response) => {
          if (response.status === 200) {
            // Set token in localStorage
            localStorage.setItem('token_auth', response.data.token_auth);

            store.dispatch(
              setAdminLogged({
                id: response.data.user.id,
                email: response.data.user.email,
                username: response.data.user.username,
              })
            );
            store.dispatch(
              openSnackbar({
                message: `Welcome back ${response.data.user.username}`,
                severity: `${response.data.status || 'success'}`,
              })
            );

            store.dispatch(redirectTo('/'));
          }
        })
        .catch((err) => {
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data?.message || 'Erreur lors de la connexion'}`,
              severity: `${err.response?.data?.status || 'error'}`,
            })
          );
        })
        .finally(() => {
          store.dispatch(redirectTo(null));
          store.dispatch(stopLoading());
        });
      break;
    }
    case RESET_PASSWORD: {
      // Remove item from localstorage
      localStorage.removeItem('token_auth');

      // prettier-ignore
      const {
        id,
        token,
        password,
        repeat_password
      } = action.payload;

      if (
        password !== repeat_password
      ) {
        store.dispatch(
          openSnackbar({
            message: 'Les mots de passe renseignés ne correspondent aps',
            severity: 'warning',
          })
        );
      }
      // prettier-ignore
      else {
        store.dispatch(startLoading());

        instance
          .post(`/auth/resetPwd/${id}/${token}`, { password, repeat_password })
          .then((response) => {
            if (response.status === 200) {
              store.dispatch(
                openSnackbar({
                  message: `${response?.data?.message || 'Informations mises à jour'}`,
                  severity: `${response.data.status || 'success'}`,
                })
              );

              store.dispatch(redirectTo(null));
            }
          })
          .catch((err) => {
            console.log(err);
            store.dispatch(
              openSnackbar({
                message: 'Une erreur est survenue, merci de réitérer',
                severity: 'error',
              })
            );
          })
          .finally(() => {
            store.dispatch(stopLoading());
          });
      }

      break;
    }
    default:
      next(action);
  }
};

export default authMiddleware;
