import { connect } from 'react-redux';
import AtelierDetailsPage from '../pages/AtelierDetailsPage';

import { getAtelierDetails, deleteAtelier } from '../actions/ateliers';
import { openModal } from '../actions/modal';

const mapStateToProps = (state) => ({
  atelier: state.ateliers.atelier,
  redirectUrl: state.redirect.redirectUrl,
  isModalOpen: state.modal.isModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
  getAtelierDetails: (id) => dispatch(getAtelierDetails(id)),
  deleteAtelier: (id) => dispatch(deleteAtelier(id)),
  openModal: (payload) => dispatch(openModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AtelierDetailsPage);
