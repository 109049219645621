import { SET_ADMIN_LOGGED, LOGOUT_USER } from '../actions/auth';

export const initialState = {
  isAdminLogged: false,
  username: null,
  id: null,
  email: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...initialState,
      };
    case SET_ADMIN_LOGGED:
      return {
        ...state,
        isAdminLogged: true,
        username: action.payload.username,
        id: action.payload.id,
        email: action.payload.email,
      };
    default:
      return state;
  }
};

export default reducer;
