/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Navigate } from 'react-router-dom';

// DAYJS & CALENDARS
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// MUI
import {
  TextField,
  Container,
  Button,
  FormControl,
  Select,
  Box,
  Switch,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Grid,
  Typography
} from '@mui/material';

import RichTextfieldComponent from '../Utils/UIComponents/RichTextfieldComponent';

// Svschema
const eventSchema = yup.object().shape({
  title: yup.string().required(),
  program: yup.string().required(),
  categories: yup.array().of(yup.string().min(24).max(24)),
  subcategories: yup.array().of(yup.string().min(24).max(24)),
  audience: yup.string().required(),
  prerequisites: yup.string().required(),
  objectives: yup.string().required(),
  modalities: yup.string().required(),
  dateStart: yup.date().required(),
  dateEnd: yup.date().required(),
  location: yup.string().required(),
  price: yup.number().required().typeError("Le prix de l'événement doit être indiqué en chiffres").min(0),
  intra: yup.bool().required(),
  inter: yup.bool().required(),
  certification: yup.bool().required(),
  display: yup.bool().required()
});

// prettier-ignore
const NewEventForm = ({
  createEvent,
  categories,
  subcategories,
  redirectUrl,
}) => {
  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  const today = dayjs();

  const [catList, setCatList] = useState([]);
  const [subcatList, setSubcatList] = useState([]);
  const [selectedCats, setSelectedCats] = useState([]);
  const [selectedSubcats, setSelectedSubcats] = useState([]);
  const [dateStart, setDateStart] = useState(today);
  // eslint-disable-next-line no-unused-vars
  const [dateEnd, setDateEnd] = useState(null);

  // Hook form init
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(eventSchema),
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setCatList(categories);
      setSubcatList(subcategories);
    }

    return () => {
      isMounted = false;
    }; // cleanup toggles value, if unmounted
  }, [categories, subcategories]);

  const handleAddEvent = (data) => {
    const formData = { ...data, categories: selectedCats, subcategories: selectedSubcats };
    console.log('form Data :', formData);
    createEvent(formData);
  };

  // CATEGORIES + SUBCATEGORIES
  const handleChangeCategory = (event) => {
    const { target: { value } } = event;
    setSelectedCats(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeSubcategory = (event) => {
    const { target: { value } } = event;
    setSelectedSubcats(typeof value === 'string' ? value.split(',') : value);
  };

  const CustomTextFieldInput = ({
    name, label, multiline, type
  }) => (
    <FormControl fullWidth>
      <Controller
        name={name}
        defaultValue={''}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            id={name}
            data-testid={name}
            type={type || 'text'}
            label={label}
            multiline={multiline}
            errors={`errors.${name}`}
            helperText={`errors.${name}` && `${label} requis`}
            {...field}
          />
        )}
      />
    </FormControl>
  );

  return (
    <Container
      className="addEventForm"
      sx={{
        maxWidth: '600px',
        paddingBottom: '2rem',
      }}
    >
      <Typography variant="h5" textAlign="left" paddingBottom="1rem">
        Créer une nouvelle formation
      </Typography>
      <form className="addEventForm-form" data-testid="addEventForm-form" onSubmit={handleSubmit(handleAddEvent)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            textAlign: 'left',
          }}
        >
          {/* TITLE */}
          <CustomTextFieldInput
            className="addEventForm-input"
            name={'title'}
            multiline={false}
            label={'Nom de la formation'}
          />
          {/* PROGRAM */}
          <>
            <Typography id="program-label" sx={{ color: '#888' }}>
              Programme de la formation :
            </Typography>

            <FormControl fullWidth>
              <Controller
                className="addEventForm-input"
                name={'program'}
                control={control}
                defaultValue={''}
                label={'Programme de la formation'}
                rules={{ required: true }}
                render={({ field }) => <RichTextfieldComponent {...field} />}
              />
            </FormControl>
          </>
          {/* Categories */}
          <FormControl fullWidth variant="outlined" error={!!errors.categories}>
            <>
              <InputLabel id="category-label">Catégories :</InputLabel>
              <Select
                id="categories"
                onChange={handleChangeCategory}
                inputProps={{ 'data-testid': 'category' }}
                labelId="category-label"
                label="Choisissez une catégorie"
                value={selectedCats}
                multiple
              >
                {catList &&
                  catList.length > 0 &&
                  catList.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.catTitle}
                    </MenuItem>
                  ))}
              </Select>
            </>

            {errors.categories && <FormHelperText>La categorie est requise</FormHelperText>}
          </FormControl>
          {/* Sub Category */}
          <FormControl fullWidth variant="outlined" error={!!errors.subcategories}>
            <>
              <InputLabel id="category-label">Sous-catégories :</InputLabel>
              <Select
                id="subcategories"
                onChange={handleChangeSubcategory}
                inputProps={{ 'data-testid': 'subcategory' }}
                labelId="category-label"
                label="Choisissez une sous-catégorie"
                value={selectedSubcats}
                multiple
              >
                {subcatList &&
                  subcatList.length > 0 &&
                  subcatList
                    .filter((subcat) => subcat.subCatTitle !== 'ateliers')
                    .map((subcategory) => (
                      <MenuItem key={subcategory._id} value={subcategory._id}>
                        {subcategory.subCatTitle}
                      </MenuItem>
                    ))}
              </Select>
            </>
            {errors.subcategories && <FormHelperText>Sous categorie requise</FormHelperText>}
          </FormControl>
          {/* Audience */}
          <CustomTextFieldInput
            className="addEventForm-input"
            name={'audience'}
            multiline={true}
            label={'Cible de la formation'}
          />
          {/* Pre requisites */}
          <CustomTextFieldInput
            className="addEventForm-input"
            name={'prerequisites'}
            multiline={true}
            label={'Pré-requis'}
          />
          {/* OBJECTIVES */}
          <>
            <Typography id="objectives-label" sx={{ color: '#888' }}>
              Objectifs de la formation :
            </Typography>

            <FormControl fullWidth>
              <Controller
                className="addEventForm-input"
                name={'objectives'}
                control={control}
                defaultValue={''}
                label={'Objectifs de la formation'}
                rules={{ required: true }}
                render={({ field }) => <RichTextfieldComponent {...field} />}
              />
            </FormControl>
          </>
          {/* MODALITIES */}
          <>
            <Typography id="Modalities-label" sx={{ color: '#888' }}>
              Modalités pédagogiques :
            </Typography>
            <FormControl fullWidth>
              <Controller
                className="addEventForm-input"
                name={'modalities'}
                control={control}
                defaultValue={''}
                label={'Modalités pédagogiques :'}
                rules={{ required: true }}
                render={({ field }) => <RichTextfieldComponent {...field} />}
              />
            </FormControl>
          </>

          {/* <CustomTextFieldInput
            className="addEventForm-input"
            name={'modalities'}
            multiline={true}
            label={'Modalités pédagogiques'}
          /> */}
          {/* DATESTART */}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Controller
                    className="addEventForm-input"
                    name="dateStart"
                    control={control}
                    defaultValue={today}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        id="dateStart"
                        label="Date de début"
                        errors={errors.dateStart}
                        allowSameDateSelection
                        onAccept={(date) => setDateStart(date)}
                        onError={(err) => console.log(err)}
                        renderInput={(props) => <TextField {...props} />}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {/* DATEEND */}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Controller
                    className="addEventForm-input"
                    name="dateEnd"
                    control={control}
                    defaultValue={dateStart}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        id="dateEnd"
                        label="Date de fin"
                        minDate={dateStart}
                        errors={errors.dateEnd}
                        onAccept={(date) => setDateEnd(date)}
                        renderInput={(props) => <TextField {...props} />}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </LocalizationProvider>
          {/* LOCATION */}
          <CustomTextFieldInput className="addEventForm-input" name={'location'} multiline={false} label={'Lieu'} />
          {/* PRICE */}
          <CustomTextFieldInput
            className="addEventForm-input"
            name={'price'}
            multiline={false}
            label={'Prix'}
            type={'number'}
          />
          <Grid container spacing={2}>
            {/* INTRA */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  className="addEventForm-input"
                  name="intra"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel control={<Switch />} label="Intra-entreprise" errors={errors.intra} {...field} />
                  )}
                />
                {errors.intra && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
              </FormControl>
            </Grid>
            {/* INTER */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  className="addEventForm-input"
                  name="inter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel control={<Switch />} label="Inter-entreprise" errors={errors.inter} {...field} />
                  )}
                />
                {errors.inter && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
              </FormControl>
            </Grid>
            {/* CERTIFICATION */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  className="addEventForm-input"
                  name="certification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch />}
                      label="Certifiante"
                      errors={errors.certification}
                      {...field}
                    />
                  )}
                />
                {errors.certification && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
              </FormControl>
            </Grid>
            {/* DISPLAY */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  className="addEventForm-input"
                  name="display"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel control={<Switch />} label="Visible" errors={errors.display} {...field} />
                  )}
                />
                {errors.display && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            data-testid="submitBtn"
            variant="contained"
            className="addEventForm-btn"
            // onClick={() => console.log('clicked on button')}
          >
            Créer formation
          </Button>
        </Box>
      </form>
    </Container>
  );
};

NewEventForm.propTypes = {
  // COMPONENT PROPTYPES
  redirectUrl: PropTypes.string,
  createEvent: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      catTitle: PropTypes.string
    })
  ),
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      catTitle: PropTypes.string
    })
  ),
  // CUSTOM TEXTFIELD PROPTYPES
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  multiline: PropTypes.bool
};

NewEventForm.defaultProps = {
  type: '',
  name: '',
  label: '',
  multiline: true
};

export default NewEventForm;
