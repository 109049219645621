/* eslint-disable camelcase */
import { useState } from 'react';

import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useParams } from 'react-router-dom';

// MUI
// prettier-ignore
import {
  TextField,
  Container,
  Button,
  FormControl,
  InputAdornment,
  IconButton
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

// Svschema
const resetPwdSchema = yup.object().shape({
  password: yup.string().required(),
  repeat_password: yup.string().required(),
});

const ResetPwdForm = ({ resetPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  const { id, token } = useParams();

  // Hook form init
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(resetPwdSchema),
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmitResetPwd = (data) => {
    resetPassword({ id, token, ...data });
  };

  return (
    <Container
      className="resetPwd"
      sx={{
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      }}
    >
      <h2 className="resetPwd-title">Merci d'entrer un nouveau mot de passe</h2>
      <form className="resetPwd-form" onSubmit={handleSubmit(handleSubmitResetPwd)}>
        <FormControl
          fullWidth
          sx={{
            margin: 'normal',
            gap: '0.5rem',
          }}
        >
          {/* PASSWORD 1 */}
          <Controller
            className="resetPwd-form--onput"
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                type={showPassword ? 'text' : 'password'}
                id="password"
                label="Mot de passe"
                errors={errors.password}
                helperText={errors.password && 'Un mot de passe est requis'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Mot de passe"
                        onClick={handleShowPassword}
                        onMouseDown={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
              />
            )}
          />
          {/* PASSWORD 1 */}
          <Controller
            className="resetPwd-form--onput"
            name="repeat_password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                type={showPassword ? 'text' : 'password'}
                id="repeat_password"
                label="Mot de passe"
                errors={errors.repeat_password}
                helperText={errors.repeat_password && 'Un mot de passe est requis'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Répétez le mot de passe"
                        onClick={handleShowPassword}
                        onMouseDown={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
              />
            )}
          />
          <Button type="submit" variant="contained" className="resetPwd-btn">
            Envoyer
          </Button>
        </FormControl>
      </form>
    </Container>
  );
};

// PropTypes
ResetPwdForm.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

export default ResetPwdForm;
