import { combineReducers } from 'redux';

import snackbarReducer from './snackbar';
import adminReducer from './admin';
import loadingReducer from './loading';
import eventsReducer from './events';
import ateliersReducer from './ateliers';
import calendarReducer from './calendar';
import redirectReducer from './redirect';
import modalReducer from './modal';
import responsiveReducer from './responsive';

const rootReducer = combineReducers({
  snackbar: snackbarReducer,
  admin: adminReducer,
  loading: loadingReducer,
  events: eventsReducer,
  ateliers: ateliersReducer,
  calendar: calendarReducer,
  redirect: redirectReducer,
  modal: modalReducer,
  responsive: responsiveReducer,
});

export default rootReducer;
