export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';
export const SET_ADMIN_LOGGED = 'SET_ADMIN_LOGGED';
export const CHECK_LOGGED = 'CHECK_LOGGED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const resetPassword = (payload) => ({
  type: RESET_PASSWORD,
  payload,
});

// Logout action
export const logoutUser = () => ({
  type: LOGOUT_USER,
});

// Nav link
export const logout = () => ({
  type: LOGOUT,
});

export const checkLogged = () => ({
  type: CHECK_LOGGED,
});

export const setAdminLogged = (payload) => ({
  type: SET_ADMIN_LOGGED,
  payload,
});

export const submitLogin = (data) => ({
  type: SUBMIT_LOGIN,
  data,
});
