import { Box } from '@mui/material';
import LoginForm from '../../containers/loginFormContainer';

import './styles.scss';

const LoginPage = () => (
  <Box className="login" sx={{ maxWidth: '600px', marginInline: 'auto' }}>
    <h2 className="login-title">Se connecter</h2>
    <LoginForm />
  </Box>
);

export default LoginPage;
