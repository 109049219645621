/* eslint-disable brace-style */
/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// prettier-ignore
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

// prettier-ignore
import {
  Cancel
} from '@mui/icons-material';

import AddSubcatToCatComponent from '../../../containers/addSubcatToCatContainer';
import DetachBtnComponent from '../../../containers/detachBtnComponentContainer';
import ChipComponent from '../../Utils/UIComponents/ChipComponent';

// prettier-ignore
const CategoriesList = ({
  categories,
  subcategories,
}) => {
  // LOCAL STATE
  const [categoriesList, setCategoriesList] = useState([]);
  const [isCatSelected, setIsCatSelected] = useState(false);
  const [catSelected, setCatSelected] = useState(null);
  const [isSubcatSelected, setIsSubcatSelected] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);

  const [catId, setCatId] = useState('');
  const [subcatId, setSubcatId] = useState('');
  const [catFilters, setCatFilters] = useState({
    category: null,
    subcategory: null,
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (catFilters.category !== null) setCatSelected(categories.find((cat) => cat._id === catFilters.category));
    }
    return () => { isMounted = false; };
  }, [catFilters]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setCategoriesList([...categories]);

      // Reset selected category & subcategory
      setCatFilters({ ...catFilters, category: null, subcategory: null });
      setCatId('');
      setSubcatId('');
      setIsCatSelected(false);
      setIsSubcatSelected(false);
    }
    return () => { isMounted = false; };
  }, [categories, subcategories]);

  const handleSelectCategory = (id) => {
    // console.log('current cat', chipRef.current);
    if (catFilters?.category === id) {
      setCatFilters({ ...catFilters, category: null });
      setCatId('');
      setIsCatSelected(false);
    } else {
      setCatFilters({ ...catFilters, category: id });
      setDisplayForm(false);
      setCatId(id);
      setIsCatSelected(true);
    }
  };

  const handleSelectSubCategory = (id) => {
    if (catFilters?.subcategory === id) {
      setCatFilters({ ...catFilters, subcategory: null });
      setSubcatId('');
      setIsSubcatSelected(false);
    } else {
      setCatFilters({ ...catFilters, subcategory: id });
      setSubcatId(id);
      setDisplayForm(false);
      setIsSubcatSelected(true);
    }
  };

  // Display all categories + Subcats
  // If category is selected => Displays category's subcategories and add button
  return (
    <Box
      className="cat-list"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Typography>
        Sélectionnez une catégorie pour ajouter ou supprimer une sous-catégorie
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <h2>Catégories :</h2>
        {categoriesList.length > 0 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '0.5em',
            }}
          >
            {categoriesList &&
              categoriesList?.map((category) => (
                <Box
                  key={category._id}
                  sx={{
                    display: 'flex',
                  }}
                  onClick={() => handleSelectCategory(category._id)}
                >
                  <ChipComponent
                    className="cat-list--category"
                    label={category.catTitle}
                    // ref={chipRef}
                    sx={{
                      margin: '0 0.3rem',
                    }}
                    color={category._id === catFilters.category ? 'success' : 'default'}
                  />
                </Box>
              ))}
            {/* {isCatSelected && <DeleteBtnComponent id={catId} target={'CATEGORY'} />} */}
          </Box>
        ) : (
          <Typography>Pas de catégorie</Typography>
        )}
      </Box>
      {/* SUBCATEGORIES */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <h2>Sous-catégories :</h2>
        {catFilters.category !== null ? (
          <>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5em',
              }}
            >
              <>
                {catSelected?.subcategories?.map((subcategory) => (
                  <Box
                    key={subcategory._id}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                    onClick={() => handleSelectSubCategory(subcategory._id)}
                  >
                    <ChipComponent
                      className="cat-list--subcategory"
                      label={subcategory.subCatTitle}
                      sx={{
                        margin: '0 0.3rem',
                      }}
                      color={subcategory._id === catFilters.subcategory ? 'success' : 'default'}
                    />
                  </Box>
                ))}
                {/* {(isSubcatSelected && isSubcatSelected.length > 0) && ( */}
                {isSubcatSelected && (
                  <>
                    <DetachBtnComponent
                      subcatId={subcatId}
                      catId={catId}
                      target={'SUBCATEGORY'}
                      onClick={() => {
                        setIsSubcatSelected(false);
                        setCatFilters({ ...catFilters, category: null });
                      }}
                    />
                    {/* <DeleteBtnComponent id={subcatId} target={'SUBCATEGORY'} /> */}
                  </>
                )}
                <Box onClick={() => setDisplayForm(!displayForm)}>
                  {isCatSelected && !isSubcatSelected && displayForm ? (
                    <Button
                      data-testid="closeBtn"
                      aria-label="close form"
                      className="addSubcatToCat-form--btn"
                      onClick={() => setDisplayForm(false)}
                    >
                      Annuler
                    </Button>
                  ) : (
                    <>
                    {!displayForm
                      && !isSubcatSelected
                      && <ChipComponent data-testid="submitBtn" className="cat-list--btn" label={'+'} color={'info'} />}
                    </>
                  )}
                </Box>
              </>
            </div>
            {isCatSelected && displayForm && (
              <>
              <Typography>Ajoutez une sous-catégorie :</Typography>
              <Box
                sx={{
                  display: 'flex',
                  width: 'min(95vw, 600px)',
                }}
              >
                <Box
                  sx={{
                    flexGrow: '2',
                  }}
                >
                  <AddSubcatToCatComponent catId={catFilters.category} />
                </Box>
              </Box>
              </>
            )}
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '0.5rem',
              }}
            >
              {subcategories &&
                subcategories.map((subcategory) => (
                  <Box key={subcategory._id}>
                    <ChipComponent
                      className="cat-list--subcategory"
                      label={subcategory.subCatTitle}
                      color={'default'}
                    />
                  </Box>
                ))}
              {/* {isSubcatSelected && <DeleteBtnComponent id={subcatId} target={'SUBCATEGORY'} />} */}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

CategoriesList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      catTitle: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    })
  ),
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      subCatTitle: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    })
  ),
};

export default CategoriesList;
