import { startLoading, stopLoading } from '../actions/loading';
import { openSnackbar } from '../actions/snackbar';

import { SEND_RESET_PWD_MAIL, SEND_NEW_EVENT_DETAILS } from '../actions/email';

import instance from './axiosInstance';

const emailsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case SEND_RESET_PWD_MAIL: {
      // !!!!! DONT FORGET STORE.DISPATCH MODAFUKA !!!!!
      // console.log('sending reset email :', action.payload);
      store.dispatch(startLoading());
      instance
        .post('/contacts/sendResetPwdMail', {
          email: action.payload.email,
        })
        .then((response) => {
          console.log(response);
          if (
            // eslint-disable-next-line operator-linebreak
            response.status === 200 &&
            response.data.status === 'ok'
          ) {
            store.dispatch(
              openSnackbar({
                message: `${response.data.message || "Requête en cours d'approbation"}`,
                severity: 'success',
              })
            );
          }
          // prettier-ignore
          else if (response.status === 200 && response.data.status === 'success') {
            store.dispatch(
              openSnackbar({
                message: 'Email envoyé, consultez votre adresse email et vos dossiers spam / promotions',
                severity: `${response.data.status || 'success'}`,
              })
            );
          }
        })
        .catch((err) => {
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data?.message || "Une erreur est survenue lors de l'envoi de l'adresse email"}`,
              severity: `${err.response?.data?.status || 'error'}`,
            })
          );
        })
        .finally(() => {
          store.dispatch(stopLoading());
        });
      break;
    }
    case SEND_NEW_EVENT_DETAILS: {
      store.dispatch(startLoading());
      instance
        .post('/contacts/new-event-details', action.payload)
        .then((response) => {
          if (response.status === 200) {
            store.dispatch(
              openSnackbar({
                message: 'Email envoyé, consultez votre adresse email et vos dossiers spam / promotions',
                severity: `${response.data.status || 'success'}`,
              })
            );
          }
        })
        .catch((err) => {
          store.dispatch(
            openSnackbar({
              message: `${err.response?.data?.message || "Une erreur est survenue lors de l'envoi de l'adresse email"}`,
              severity: `${err.response?.data?.status || 'error'}`,
            })
          );
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    default:
      next(action);
  }
};

export default emailsMiddleware;
