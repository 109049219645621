import { connect } from 'react-redux';
import PanelPage from '../pages/PanelPage';

import { getEventsList } from '../actions/events';
import { getAteliersList } from '../actions/ateliers';

const mapStateToProps = (state) => ({
  eventsList: state.events.eventsList,
  ateliersList: state.ateliers.ateliersList,
  categories: state.events.categories,
  isScreenBig: state.responsive.isScreenBig,
  subcategories: state.events.subcategories,
});

const mapDispatchToProps = (dispatch) => ({
  getAteliersList: () => dispatch(getAteliersList()),
  getEventsList: () => dispatch(getEventsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelPage);
