import { connect } from 'react-redux';
import CategoriesList from '../components/CategoriesComponents/CategoriesList';

// prettier-ignore
import {
  updateCategory,
  updateSubcategory,
  deleteCategory,
  deleteSubcategory
} from '../actions/categories';

import { openModal } from '../actions/modal';

const mapStateToProps = (state) => ({
  isModalOpen: state.modal.isModalOpen,
  categories: state.events.categories,
  subcategories: state.events.subcategories,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (payload) => dispatch(openModal(payload)),
  updateCategory: (payload) => dispatch(updateCategory(payload)),
  updateSubcategory: (payload) => dispatch(updateSubcategory(payload)),
  deleteCategory: (id) => dispatch(deleteCategory(id)),
  deleteSubcategory: (id) => dispatch(deleteSubcategory(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
