import { useState } from 'react';

import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Navigate, Link } from 'react-router-dom';

// MUI
// prettier-ignore
import {
  TextField,
  Container,
  Button,
  FormControl,
  InputAdornment,
  IconButton
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import './styles.scss';

// Svschema
const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const LoginForm = ({ submitLogin, isAdminLogged, redirectUrl }) => {
  const [showPassword, setShowPassword] = useState(false);
  // Hook form init
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const handleLogin = (data) => {
    submitLogin(data);
  };

  if (redirectUrl || isAdminLogged) {
    return <Navigate to={redirectUrl || '/'} />;
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container
      className="loginForm"
      sx={{
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      }}
    >
      <form className="loginForm-form" onSubmit={handleSubmit(handleLogin)}>
        <FormControl
          fullWidth
          sx={{
            margin: 'normal',
            gap: '0.5rem',
          }}
        >
          <Controller
            className="loginForm-input"
            name="email"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                type="email"
                id="email"
                label="Adresse email"
                errors={errors.email}
                helperText={errors.email && 'Une adresse email est requise'}
                {...field}
              />
            )}
          />
          <Controller
            className="loginForm-input"
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                type={showPassword ? 'text' : 'password'}
                id="password"
                label="Mot de passe"
                errors={errors.password}
                helperText={errors.password && 'Un mot de passe est requis'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Mot de passe"
                        onClick={handleShowPassword}
                        onMouseDown={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
              />
            )}
          />
          <Button type="submit" variant="contained" className="loginForm-btn">
            Se connecter
          </Button>
        </FormControl>
      </form>
      <Link to={'/forgotpwd'}>Mot de passe oublié ?</Link>
    </Container>
  );
};

// PropTypes
LoginForm.propTypes = {
  submitLogin: PropTypes.func.isRequired,
  isAdminLogged: PropTypes.bool.isRequired,
  redirectUrl: PropTypes.string,
};

export default LoginForm;
