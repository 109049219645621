import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const Logout = ({ logout, isAdminLogged }) => {
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      logout();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  if (!isAdminLogged) {
    return <Navigate replace to="/login" />;
  }

  return <div className="logout" />;
};

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
  isAdminLogged: PropTypes.bool.isRequired,
};

export default Logout;
