import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

import './styles.scss';

const Loading = () => {
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.scrollTo(0, 0);
    }
    return () => {
      isMounted = false;
    };
  });

  return (
    <div className="loading" role="loadingComponent">
      <CircularProgress color="secondary" />
    </div>
  );
};

export default Loading;
