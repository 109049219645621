// import { useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';

// prettier-ignore
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';

// prettier-ignore
import {
  Edit,
  Delete,
} from '@mui/icons-material';

import { NavLink } from 'react-router-dom';
import ModalComponent from '../../../../containers/modalContainer';

// prettier-ignore
const EventActionsMenu = ({
  id, subcategory, openModal, isModalOpen
}) => {
  const handleDeleteEvent = () => {
    console.log('works', subcategory);
    openModal(`${subcategory === 'ateliers' ? 'DELETE_ATELIER' : 'DELETE_EVENT'}`);
  };

  return (
    <>
      {isModalOpen && <ModalComponent id={id} />}
      <Box
        sx={{
          width: '100%',
          border: '1px solid black',
          borderRadius: 2,
          background: '#ffffff99',
          zIndex: 99,
        }}
      >
        <List>
          {/* Edit */}
          <NavLink to={`/${subcategory === 'ateliers' ? 'atelier' : 'event'}/edit/${id}`}>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              divider
            >
              <ListItemText
                primary="Edit"
                primaryTypographyProps={{
                  fontWeight: 'bold',
                }}
              />
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
            </ListItem>
          </NavLink>
          {/* Delete */}
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            onClick={handleDeleteEvent}
          >
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{
                fontWeight: 'bold',
              }}
            />
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
          </ListItem>
        </List>
      </Box>
    </>
  );
};

// const forwardedRef = forwardRef(EventActionsMenu);
// export default forwardedRef;

EventActionsMenu.propTypes = {
  id: PropTypes.string.isRequired,
  subcategory: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

EventActionsMenu.defaultProps = {
  subcategory: '',
};

export default EventActionsMenu;
