import PropTypes from 'prop-types';

// MUI
// prettier-ignore
import {
  IconButton,
} from '@mui/material';

// prettier-ignore
import {
  LinkOff,
} from '@mui/icons-material';

// prettier-ignore
const DetachBtnComponent = ({
  subcatId,
  catId,
  target,
  removeSubcategory,
}) => {
  const handleSubmit = (action) => {
    switch (action) {
      case 'REMOVE_SUBCATEGORY': {
        console.log('action is :', action, subcatId, catId);
        removeSubcategory({ catId, subcatId });
        break;
      }
      default: break;
    }
  };

  return (
    <IconButton
      type="submit"
      data-testid="submitBtn-edit"
      aria-label="edit category"
      className="edit-delete--btn_edit"
      onClick={() => handleSubmit(`REMOVE_${target}`)}
    >
      <LinkOff color="primary" />
    </IconButton>
  );
};

DetachBtnComponent.propTypes = {
  catId: PropTypes.string,
  subcatId: PropTypes.string,
  target: PropTypes.string,
  removeSubcategory: PropTypes.func.isRequired,
};

DetachBtnComponent.defaultProps = {
  catId: '',
  subcatId: '',
  target: '',
};

export default DetachBtnComponent;
