import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import './styles.scss';

const DBConnectionComponent = ({ openSnackbar }) => {
  const [dbIsConnected, setDbIsConnected] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`${process.env.REACT_APP_API_URL}/`)
      .then((response) => {
        if (response.status === 200 && isMounted) {
          setDbIsConnected(true);
        }
      })
      .catch(() => {
        openSnackbar({
          message: `${'Une erreur est survenue lors de la connection avec la base de données'}`,
          severity: `${'error'}`,
        });
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setTimeout(() => setIsOpen(false), 4000);
  }, []);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={isOpen ? 'dbStatus open' : 'dbStatus close'}
      onClick={isOpen ? handleClose : handleOpen}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <div className={dbIsConnected ? 'dbStatus-chip connected' : 'dbConnexion unconnected'} />
      {dbIsConnected && <p role="dbConnectionComponent">Connecté à la base de données</p>}
    </div>
  );
};

DBConnectionComponent.propTypes = {
  openSnackbar: PropTypes.func.isRequired,
};

export default DBConnectionComponent;
