/* eslint-disable operator-linebreak */
import PropTypes from 'prop-types';

// prettier-ignore
import {
  Box,
  Typography,
  Grid
} from '@mui/material';

// Event component
import EventComponent from './EventComponent';

// eslint-disable-next-line react/display-name
const EventsList = ({ eventsList }) => (
  <Box
    className="eventsList"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem'
    }}
  >
    <Grid container spacing={2}>
      {eventsList.length > 0 ? (
        eventsList.map((event, index) => (
          <Grid key={index} item xs={12} md={6}>
            <EventComponent event={event} index={index} />
          </Grid>
        ))
      ) : (
        <Typography sx={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}>Pas d'événement</Typography>
      )}
    </Grid>
  </Box>
);

EventsList.propTypes = {
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          catTitle: PropTypes.string,
          _id: PropTypes.string,
          subcategories: PropTypes.arrayOf(
            PropTypes.shape({
              subCatTitle: PropTypes.string,
              _id: PropTypes.string,
              categories: PropTypes.arrayOf(PropTypes.string)
            })
          )
        })
      ),
      subcategories: PropTypes.arrayOf(
        PropTypes.shape({
          subCatTitle: PropTypes.string,
          _id: PropTypes.string,
          categories: PropTypes.arrayOf(
            PropTypes.shape({
              catTitle: PropTypes.string,
              _id: PropTypes.string,
              subcategories: PropTypes.arrayOf(PropTypes.string)
            })
          )
        })
      ),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string,
      dateEnd: PropTypes.string,
      duration: PropTypes.string,
      location: PropTypes.string.isRequired,
      price: PropTypes.number,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired
    })
  )
};

EventsList.defaultProps = {
  dateStart: '',
  dateEnd: '',
  duration: '',
  price: null
};

export default EventsList;
