import { connect } from 'react-redux';
import Logout from '../components/Utils/Services/Logout';

import { logout } from '../actions/auth';

const mapStateToProps = (state) => ({
  isAdminLogged: state.admin.isAdminLogged,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
