import { connect } from 'react-redux';
import Header from '../components/NavComponent';

const mapStateToProps = (state) => ({
  isAdminLogged: state.admin.isAdminLogged,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
