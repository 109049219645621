import { connect } from 'react-redux';
import HomeCalendar from '../components/HomeCalendar';

import { setDateRange } from '../actions/calendar';

const mapStateToProps = (state) => ({
  eventsList: state.events.eventsList,
  selectedDateRange: state.calendar.selectedDateRange,
  isLoading: state.loading.isLoading,
  isScreenBig: state.responsive.isScreenBig,
});

const mapDispatchToProps = (dispatch) => ({
  setDateRange: (payload) => dispatch(setDateRange(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeCalendar);
