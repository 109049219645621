export const GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST';
export const SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST';
export const GET_SUBCATEGORIES_LIST = 'GET_SUBCATEGORIES_LIST';
export const SET_SUBCATEGORIES_LIST = 'SET_SUBCATEGORIES_LIST';

// CREATE + UPDATE + DELETE
export const CREATE_SUBCATEGORY = 'CREATE_SUBCATEGORY';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_SUBCATEGORY = 'UPDATE_SUBCATEGORY';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY';

// UPDATE CATEGORIES + SUBCATEGORIES LISTS
export const UPDATE_CATEGORY_SUBCATEGORIES_LIST = 'UPDATE_CATEGORY_SUBCATEGORIES_LIST';
export const REMOVE_SUBCATEGORY = 'REMOVE_SUBCATEGORY';

// CREATE
export const createCategory = (payload) => ({
  type: CREATE_CATEGORY,
  payload,
});

export const createSubcategory = (payload) => ({
  type: CREATE_SUBCATEGORY,
  payload,
});

// UPDATE
export const updateCategory = (id) => ({
  type: UPDATE_CATEGORY,
  id,
});

export const updateSubcategory = (id) => ({
  type: UPDATE_SUBCATEGORY,
  id,
});

// DELETE
export const deleteCategory = (id) => ({
  type: DELETE_CATEGORY,
  id,
});
export const deleteSubcategory = (id) => ({
  type: DELETE_SUBCATEGORY,
  id,
});

// GET + SET

export const getCategoriesList = () => ({
  type: GET_CATEGORIES_LIST,
});

export const getSubcategorieslist = () => ({
  type: GET_SUBCATEGORIES_LIST,
});

export const setCategoriesList = (payload) => ({
  type: SET_CATEGORIES_LIST,
  payload,
});

export const setSubcategorieslist = (payload) => ({
  type: SET_SUBCATEGORIES_LIST,
  payload,
});

// UPDATE CATEGORIES + SUBCATEGORIES LISTS
export const updateCategorySubcategoriesList = (payload) => ({
  type: UPDATE_CATEGORY_SUBCATEGORIES_LIST,
  payload,
});

export const removeSubcategory = (payload) => ({
  type: REMOVE_SUBCATEGORY,
  payload,
});
