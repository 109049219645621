import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './styles.scss';

const Header = ({ isAdminLogged }) => (
  <nav className="header">
    {isAdminLogged ? (
      <>
        <NavLink className="header-link" role="headerNavlink" to="/">
          <h4>Accueil</h4>
        </NavLink>
        <NavLink className="header-link" role="headerNavlink" to="/panel">
          <h4>Formations</h4>
        </NavLink>
        <NavLink className="header-link" role="headerNavlink" to="/logout">
          <h4>Déconnexion</h4>
        </NavLink>
      </>
    ) : (
      <NavLink className="header-link" role="headerNavlink" to="/login">
        <h4>Connexion</h4>
      </NavLink>
    )}
  </nav>
);

Header.propTypes = {
  isAdminLogged: PropTypes.bool.isRequired,
};

export default Header;
