/* eslint-disable no-underscore-dangle */
import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { addDays, format } from 'date-fns';

import { Chip, Box, Typography } from '@mui/material';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

dayjs.extend(duration); // theme css file

const HomeCalendar = ({ eventsList, selectedDateRange, isScreenBig }) => {
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    color: '',
  });
  const [labelContent, setLabelContent] = useState(null);

  useEffect(() => {
    setSelectedRange({
      startDate: new Date(selectedDateRange.start),
      endDate: new Date(selectedDateRange.end),
      color: '#1d70b7',
    });
    setLabelContent(selectedDateRange.title);
  }, [selectedDateRange]);

  const nextEvents = useMemo(() => {
    const events = [];
    eventsList?.forEach((event) => {
      if (event.dateStart !== null && event.dateEnd !== null) {
        events.push({
          startDate: new Date(event.dateStart),
          endDate: new Date(event.dateEnd),
          key: event.title,
          value: event.category?.catTitle,
          autoFocus: false,
        });
      }
    });
    return events;
  }, [eventsList]);

  const customRender = (day) => (
    <span
      onMouseOver={() => {
        eventsList.forEach((event) => {
          if (dayjs(day).isBetween(dayjs(event.dateStart), dayjs(event.dateEnd))) {
            setLabelContent(event.title);
          }
        });
      }}
      onMouseLeave={() => setLabelContent(null)}
    >
      {format(day, 'd')}
    </span>
  );

  // READ ONLY CALENDAR WITH NEXT EVENTS
  return (
    <div className="homeCalendar">
      <h2>Vos prochains événements</h2>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isScreenBig ? 'row' : 'column',
          gap: '1rem',
          alignItems: isScreenBig ? 'baseline' : 'center',
          justifyContent: 'center',
          padding: '1rem 0 2rem',
          maxWidth: isScreenBig ? '100%' : '95vw',
          marginInline: 'auto',
        }}
      >
        Événement sélectionné :
        {labelContent ? (
          <Typography variant="h4">{labelContent}</Typography>
        ) : (
          <Chip label={"Pas d'événement ce jour"} />
        )}
      </Box>
      <DateRange
        ranges={nextEvents}
        preview={selectedRange}
        showPreview={!!selectedDateRange}
        dragSelectionEnabled={false}
        moveRangeOnFirstSelection={false}
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        // minDate={new Date()}
        maxDate={addDays(new Date(), 365)}
        months={isScreenBig ? 2 : 1}
        dayContentRenderer={customRender}
        direction={isScreenBig ? 'horizontal' : 'vertical'}
      />
    </div>
  );
};

HomeCalendar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isScreenBig: PropTypes.bool.isRequired,
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string.isRequired,
      dateEnd: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),
  setDateRange: PropTypes.func.isRequired,
  selectedDateRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
  }),
};

export default HomeCalendar;
