import { useEffect } from 'react';
import PropTypes from 'prop-types';

// prettier-ignore
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

// prettier-ignore
const ModalComponent = ({
  closeModal,
  isModalOpen,
  modalAction,
  deleteEvent,
  deleteAtelier,
  id,
  catId,
}) => {
  // Disable scroll when open
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // document.body.style.overflow = 'hidden';
    }
    return () => {
      // document.body.style.overflow = 'unset';
      isMounted = false;
    };
  }, []);

  const handleModalAction = () => {
    switch (modalAction) {
      case 'DELETE_EVENT': {
        deleteEvent(id);
        closeModal();
        break;
      }
      case 'DELETE_ATELIER': {
        console.log('id is', id);
        deleteAtelier(id);
        closeModal();
        break;
      }
      case 'DELETE_CATEGORY': {
        console.log('we getting here', catId);
        closeModal();
        break;
      }
      default:
        break;
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {
        closeModal();
      }}
      sx={{
        overflow: `${isModalOpen ? 'hidden' : 'unset'}`,
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="modal"
    >
      <DialogTitle id="alert-dialog-title">{'Voulez-vous vraiment supprimer cet élément ?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Cette action est irréversible. <br />
          Désirez vous quand-même supprimer cet élément ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="cancel" onClick={closeModal}>
          Annuler
        </Button>
        <Button id="delete" variant="contained" onClick={handleModalAction}>
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalComponent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  modalAction: PropTypes.string.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  deleteAtelier: PropTypes.func.isRequired,
  id: PropTypes.string,
  catId: PropTypes.string,
  subcatId: PropTypes.string,
};

ModalComponent.defaultProps = {
  id: '',
  catId: '',
  subcatId: '',
};

export default ModalComponent;
