/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
// prettier-ignore
import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';

// Import middlewares
import authMiddleWare from '../middlewares/authMiddleware';
import eventsMiddleware from '../middlewares/eventsMiddleware';
import categoriesMiddleware from '../middlewares/categoriesMiddleware';
import emailsMiddleware from '../middlewares/emailsMiddleware';
import ateliersMiddleware from '../middlewares/ateliersMiddleware';

// Import reducers
import reducer from '../reducers';

// Store is returned by configureStore() function
// Better for testing as we can create a new store on demand
// When needed just use :
// import configureStore from '../store';
// const store = configureStore();
export default function configureStore() {
  // prettier-ignore
  const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // prettier-ignore
  const enhancers = composeEnhancers(
    applyMiddleware(
      authMiddleWare,
      eventsMiddleware,
      categoriesMiddleware,
      emailsMiddleware,
      ateliersMiddleware
    )
  );

  const store = createStore(reducer, enhancers);

  return store;
}
