/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

// prettier-ignore
import {
  Button,
  Box,
  TextField,
  InputAdornment,
  Grid,
  Typography
} from '@mui/material';
import { Search } from '@mui/icons-material';

import EventsList from '../../components/EventsList';
import ChipComponent from '../../components/Utils/UIComponents/ChipComponent';

// prettier-ignore
const PanelPage = ({
  eventsList,
  ateliersList,
  categories,
  subcategories,
  getEventsList,
  getAteliersList,
  isScreenBig,
}) => {
  const [list, setList] = useState([]);

  const [catFilters, setCatFilters] = useState({
    category: null,
    subcategory: null,
  });

  useEffect(() => {
    setList(() => [...eventsList, ...ateliersList]);
  }, [eventsList, ateliersList]);

  const [search, setSearch] = useState('');

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getEventsList();
      getAteliersList();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const filteredEvents = useMemo(() => {
    // HANDLE RESEARCH BAR
    if (search?.length > 0) {
      // For less strict results, use include instead of startsWith
      return list.filter((e) => e.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    }

    // HANDLE CATEGORY + SUBCATEGORY SELECTED
    if (catFilters.category !== null && catFilters.subcategory !== null) {
      // eslint-disable-next-line max-len
      const filteredList = list.filter((event) => event.categories?.find((cat) => cat._id === catFilters.category));

      return filteredList?.filter((event) => {
        // If categories.length > 0 => event
        if (event.subcategories?.length > 0) {
          return event.subcategories?.find((subcat) => subcat._id === catFilters.subcategory);
        }

        // Else atelier
        return event.subcategory?._id === catFilters.subcategory;
      });
    }

    // Category filter selected
    if (catFilters.category !== null) {
      return list.filter((e) => e.categories?.find((cat) => cat._id === catFilters.category));
    }

    // Subategory filter selected
    if (catFilters.subcategory !== null) {
      // Handle ateliers
      const atelierId = subcategories.find((subcat) => subcat.subCatTitle === 'ateliers');
      if (catFilters.subcategory === atelierId._id) {
        return list.filter((e) => e.subcategory?._id === catFilters.subcategory);
      }

      // Else return event
      return list.filter((e) => e.subcategories?.find((cat) => cat._id === catFilters.subcategory));
    }

    return list;
  }, [search, list, catFilters]);

  const handleSelectCategory = (id) => {
    if (catFilters?.category === id) {
      setCatFilters({ ...catFilters, category: null });
    }
    else {
      setCatFilters({ ...catFilters, category: id });
    }
  };

  const handleSelectSubCategory = (id) => {
    if (catFilters?.subcategory === id) {
      setCatFilters({ ...catFilters, subcategory: null });
    }
    else {
      setCatFilters({ ...catFilters, subcategory: id });
    }
  };

  return (
    <div
      className="panel"
      style={{
        width: 'min(90%, 1200px)',
        marginInline: 'auto',
        paddingBottom: '1rem',
      }}
    >
      <h2>Admin Panel</h2>

      {/* Add formation + atelier */}
      <Button
        variant="contained"
        sx={{
          margin: `${isScreenBig ? '1rem 0.5rem' : '0.5rem 0.5rem 0'}`,
          width: `${isScreenBig ? '15rem' : '95%'}`,
        }}
      >
        <NavLink to="/event/new">Ajouter une formation</NavLink>
      </Button>
      <Button
        variant="contained"
        sx={{
          margin: `${isScreenBig ? '1rem 0.5rem' : '0.5rem 0.5rem 1rem'}`,
          width: `${isScreenBig ? '15rem' : '95%'}`,
        }}
      >
        <NavLink to="/atelier/new">Ajouter un atelier</NavLink>
      </Button>

      {/* Searchbar */}
      <Box>
        <TextField
          id="search"
          name="search"
          label="Rechercher une formation"
          onChange={handleSearch}
          variant="filled"
          sx={{
            marginBottom: '2em',
            width: '100%',
          }}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              </>
            ),
          }}
        />
      </Box>
      {/* Categories */}
      <Grid container spacing={2} sx={{ marginBottom: '2rem' }}>
        <Grid
          item
          container
          sm={6}
          xs={12}
          alignItems="start"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <Typography sx={{ textAlign: 'start', marginRight: '0.5rem' }}>Catégories :</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            {categories
              && categories.map((category) => (
                <Box onClick={() => handleSelectCategory(category._id)} key={category._id}>
                  <ChipComponent
                    className="panel-category"
                    label={category.catTitle}
                    color={category._id === catFilters.category ? 'success' : 'default'}
                  />
                </Box>
              ))}
          </Box>
        </Grid>
        {/* Sous catégories */}
        <Grid
          item
          container
          sm={6}
          xs={12}
          alignItems="start"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <Typography sx={{ textAlign: 'start', marginRight: '0.5rem' }}>Sous-catégories :</Typography>
          <Box
            sx={{
              display: 'inline-flex',
              flexWrap: 'wrap',
              gap: '1rem',
              alignItems: 'center',
            }}
          >
            {subcategories
              && subcategories?.map((category) => (
                <Box onClick={() => handleSelectSubCategory(category._id)} key={category._id}>
                  <ChipComponent
                    className="panel-subcategory"
                    label={category.subCatTitle}
                    color={category._id === catFilters.subcategory ? 'success' : 'default'}
                  />
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
      {/* Events list */}
      <h3 style={{ marginBottom: '1rem' }}>Liste des formations</h3>
      {list.length < 1 ? <p>No event found</p> : <EventsList eventsList={filteredEvents} />}
    </div>
  );
};

PanelPage.propTypes = {
  // Event
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string.isRequired,
      dateEnd: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),

  // Event
  ateliersList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),

  // Functions
  isScreenBig: PropTypes.bool.isRequired,

  // Functions
  getEventsList: PropTypes.func.isRequired,
  getAteliersList: PropTypes.func.isRequired,

  // Categories
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      catTitle: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    })
  ),
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      subCatTitle: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    })
  ),
};

export default PanelPage;
