import { connect } from 'react-redux';
import ModalComponent from '../components/Utils/UIComponents/ModalComponent';

import { closeModal } from '../actions/modal';
import { deleteEvent } from '../actions/events';
import { deleteAtelier } from '../actions/ateliers';

const mapStateToProps = (state) => ({
  isModalOpen: state.modal.isModalOpen,
  modalAction: state.modal.modalAction,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
  deleteEvent: (id) => dispatch(deleteEvent(id)),
  deleteAtelier: (id) => dispatch(deleteAtelier(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
