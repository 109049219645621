import { connect } from 'react-redux';
import EventDetailsPage from '../pages/EventDetailsPage';

import { getEventDetails, deleteEvent } from '../actions/events';
import { openModal } from '../actions/modal';

const mapStateToProps = (state) => ({
  event: state.events.event,
  redirectUrl: state.redirect.redirectUrl,
  isModalOpen: state.modal.isModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
  getEventDetails: (id) => dispatch(getEventDetails(id)),
  deleteEvent: (id) => dispatch(deleteEvent(id)),
  openModal: (payload) => dispatch(openModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsPage);
