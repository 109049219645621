import { connect } from 'react-redux';
import FormaCoachPage from '../pages/FormaCoach';

import { setDateRange } from '../actions/calendar';

const mapStateToProps = (state) => ({
  isAdminLogged: state.admin.isAdminLogged,
  eventsList: state.events.eventsList,
  ateliersList: state.ateliers.ateliersList,
  isScreenBig: state.responsive.isScreenBig,
  isLoading: state.loading.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setDateRange: (payload) => dispatch(setDateRange(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormaCoachPage);
