import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// MUI
// prettier-ignore
import {
  TextField,
  Container,
  Button,
  FormControl,
} from '@mui/material';

// Svschema
const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const ForgotPwdForm = ({ sendResetPwdMail }) => {
  // Hook form init
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const handleSubmitForgotPwd = (data) => {
    sendResetPwdMail(data);
  };

  return (
    <Container
      className="forgotPwd"
      sx={{
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      }}
    >
      <h2 className="forgotPwd-title">Merci d'entrer votre adresse email</h2>
      <form className="forgotPwd-form" onSubmit={handleSubmit(handleSubmitForgotPwd)}>
        <FormControl
          fullWidth
          sx={{
            margin: 'normal',
            gap: '0.5rem',
          }}
        >
          <Controller
            className="forgotPwd-input"
            name="email"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                type="email"
                id="email"
                label="Adresse email"
                errors={errors.email}
                helperText={errors.email && 'Une adresse email est requise'}
                {...field}
              />
            )}
          />
          <Button type="submit" variant="contained" className="forgotPwd-btn">
            Envoyer
          </Button>
        </FormControl>
      </form>
    </Container>
  );
};

// PropTypes
ForgotPwdForm.propTypes = {
  sendResetPwdMail: PropTypes.func.isRequired,
};

export default ForgotPwdForm;
