/* eslint-disable no-underscore-dangle */
// React
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, NavLink, Navigate } from 'react-router-dom';

// Handle Time
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';

import './styles.scss';

// MUI
// prettier-ignore
import {
  Box,
  Grid,
  Typography,
  Button
} from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';

// Components & Hooks
import useFormatDate from '../../customHooks/useFormatDate';
import ModalComponent from '../../containers/modalContainer';
import DangerouslyDisplayHTMLComponent from '../../components/Utils/UIComponents/DangerouslyDisplayHTMLComponent';
import ChipComponent from '../../components/Utils/UIComponents/ChipComponent';
import ImgComponent from '../../components/Utils/UIComponents/ImgComponent';

dayjs.extend(relativeTime);
dayjs.locale('fr');

// prettier-ignore
const EventDetailsPage = ({
  getEventDetails,
  event,
  redirectUrl,
  openModal,
  isModalOpen
}) => {
  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  const { id, index } = useParams();

  const [imgLink, setImgLink] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getEventDetails(id);
    }

    return () => { isMounted = false; };
  }, []);

  const {
    title,
    program,
    categories,
    subcategories,
    audience,
    prerequisites,
    objectives,
    modalities,
    dateStart,
    dateEnd,
    location,
    price,
    intra,
    inter,
    certification,
    display,
  } = event;

  useEffect(() => {
    let isMounted = true;

    if (isMounted && categories?.length > 0) {
      setImgLink(`${categories[0]?.catTitle}/category-${categories[0]?.catTitle}-${index || 2}-big.jpg`);
    }

    return () => {
      isMounted = false;
    };
  }, [event]);

  const end = dayjs(dateEnd).add(1, 'day');

  const handleDeleteEvent = () => {
    openModal('DELETE_EVENT');
  };

  return (
    <>
      {isModalOpen && <ModalComponent id={id} />}
      <Box
        className="eventDetails"
        sx={{
          padding: '1em 0',
          width: 'min(95%, 1200px)',
          marginInline: 'auto',
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            textAlign: 'left',
          }}
        >
          <Grid item sm={6} xs={12}>
            <h6 className="eventDetails-title">{title}</h6>
            <DangerouslyDisplayHTMLComponent content={program} />
          </Grid>
          <Grid item sm={6} xs={12}>
            {imgLink && <ImgComponent url={imgLink} />}
          </Grid>
          {/* LEFT BLOCK */}
          <Grid item container sm={6} xs={12} spacing={3} alignContent={'start'}>
            <Grid item xs={6}>
              <h4 className="grid-item--title">Catégories:</h4>
              {categories?.length > 0 ? (
                categories?.map((category) => <ChipComponent key={category._id} label={category.catTitle} />)
              ) : (
                <Typography>Pas de catégorie</Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <h4 className="grid-item--title">Sous-catégories:</h4>
              {subcategories?.length > 0 ? (
                subcategories?.map((subcategory) => (
                  <ChipComponent key={subcategory._id} label={subcategory.subCatTitle} />
                ))
              ) : (
                <Typography>Pas de catégorie</Typography>
              )}
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <h4 className="grid-item--title">Date</h4>
                <h3
                  style={{
                    color: `${dayjs(dateEnd) <= dayjs() ? 'red' : 'black'}`,
                  }}
                >
                  {useFormatDate(dateStart)}
                </h3>
              </Grid>
              <Grid item xs={6}>
                <h4 className="grid-item--title">Durée</h4>
                <h3>{`${dayjs(end).to(dateStart, true)}`}</h3>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <h4 className="grid-item--title">Lieu:</h4>
              <h3>{location}</h3>
            </Grid>
            <Grid item xs={6}>
              <h4 className="grid-item--title">Prix:</h4>
              <h3>{price} €</h3>
            </Grid>
            {/* PICTOS BLOCK */}
            <Grid item container spacing={3} alignItems={'start'}>
              <Grid item container xs={6} alignItems={'center'}>
                <h4>Intra-entreprise : </h4>
                {intra ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
              </Grid>
              <Grid item container xs={6} alignItems={'center'}>
                <h4>Inter-entreprise : </h4>
                {inter ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
              </Grid>
              <Grid item container xs={6} alignItems={'center'}>
                <h4>Certification : </h4>
                {certification ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
              </Grid>
              <Grid item container xs={6} alignItems={'center'}>
                <h4>Visible : </h4>
                {display ? <CheckCircle color={'success'} /> : <Cancel sx={{ color: '#951b81' }} />}
              </Grid>
            </Grid>
          </Grid>
          {/* RIGHT BLOCK */}
          <Grid item container sm={6} xs={12} direction="column" spacing={3}>
            <Grid item>
              <h4 className="grid-item--title">Public:</h4>
              <Typography>{audience}</Typography>
            </Grid>
            <Grid item>
              <h4 className="grid-item--title">Prérequis:</h4>
              <Typography>{prerequisites}</Typography>
            </Grid>
            <Grid item>
              <h4 className="grid-item--title">Objectifs:</h4>
              <DangerouslyDisplayHTMLComponent content={objectives} />
            </Grid>
            <Grid item>
              <h4 className="grid-item--title">Modalités:</h4>
              {/* <Typography>{modalities}</Typography> */}
            <DangerouslyDisplayHTMLComponent content={modalities} />

            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            paddingTop: '2rem',
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <NavLink
            to={`/event/edit/${id}`}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button variant="contained">Modifier l'événement</Button>
          </NavLink>
          <Button variant="outlined" onClick={handleDeleteEvent}>
            Supprimer l'événement
          </Button>
        </Box>
      </Box>
    </>
  );
};

EventDetailsPage.propTypes = {
  redirectUrl: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  getEventDetails: PropTypes.func.isRequired,
  event: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    program: PropTypes.string,

    // Array with array of subcategories
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        catTitle: PropTypes.string,
        _id: PropTypes.string,
        subcategories: PropTypes.arrayOf(
          PropTypes.shape({
            subCatTitle: PropTypes.string,
            _id: PropTypes.string,
            categories: PropTypes.arrayOf(PropTypes.string)
          })
        )
      })
    ),
    subcategories: PropTypes.arrayOf(
      PropTypes.shape({
        subCatTitle: PropTypes.string,
        _id: PropTypes.string,
        categories: PropTypes.arrayOf(
          PropTypes.shape({
            catTitle: PropTypes.string,
            _id: PropTypes.string,
            subcategories: PropTypes.arrayOf(PropTypes.string)
          })
        )
      })
    ),
    audience: PropTypes.string,
    prerequisites: PropTypes.string,
    objectives: PropTypes.string,
    modalities: PropTypes.string,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    location: PropTypes.string,
    price: PropTypes.number,
    intra: PropTypes.bool,
    inter: PropTypes.bool,
    certification: PropTypes.bool,
    display: PropTypes.bool
  })
};

EventDetailsPage.defaultProps = {
  event: PropTypes.shape({
    _id: '',
    title: '',
    program: '',
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        catTitle: '',
        _id: '',
        subcategories: PropTypes.arrayOf(
          PropTypes.shape({
            subCatTitle: '',
            _id: '',
            categories: []
          })
        )
      })
    ),
    subcategories: PropTypes.arrayOf(
      PropTypes.shape({
        subCatTitle: '',
        _id: '',
        categories: PropTypes.arrayOf(
          PropTypes.shape({
            catTitle: '',
            _id: '',
            subcategories: []
          })
        )
      })
    ),
    audience: '',
    prerequisites: '',
    objectives: '',
    modalities: '',
    dateStart: '',
    dateEnd: '',
    location: '',
    price: null,
    intra: false,
    inter: false,
    certification: false,
    display: false
  })
};

export default EventDetailsPage;
