import { connect } from 'react-redux';
import SnackbarComponent from '../components/Utils/UIComponents/Snackbar';
import { closeSnackbar, openSnackbar } from '../actions/snackbar';

const mapStateToProps = (state) => ({
  open: state.snackbar.open,
  message: state.snackbar.message,
  severity: state.snackbar.severity,
});

const mapDispatchToProps = (dispatch) => ({
  openSnackbar: (payload) => dispatch(openSnackbar(payload)),
  closeSnackbar: () => dispatch(closeSnackbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);
