import { connect } from 'react-redux';
import ForgotPwdForm from '../components/ForgotPwdForm';

import { sendResetPwdMail } from '../actions/email';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  sendResetPwdMail: (payload) => dispatch(sendResetPwdMail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPwdForm);
