import PropTypes from 'prop-types';
import { Snackbar, Alert } from '@mui/material';

const SnackbarComponent = ({
  message,
  severity,
  open,
  closeSnackbar,
}) => {
  const handleClose = () => {
    closeSnackbar();
  };

  return (
    <div className="snackbar" role="snackbarComponent">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="message"
      >
        <Alert severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

SnackbarComponent.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string,
  open: PropTypes.bool.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

SnackbarComponent.defaultProps = {
  message: 'Error',
  severity: 'error',
};

export default SnackbarComponent;
