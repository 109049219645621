import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// prettier-ignore
import {
  ContentState,
  convertToRaw,
  EditorState,
} from 'draft-js';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './styles.scss';

const RichTextfieldComponent = ({ onChange, value }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = value;
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value]);

  const onEditorStateChange = (newState) => {
    setUpdated(true);
    setEditorState(newState);

    return onChange(draftToHtml(convertToRaw(newState.getCurrentContent())));
  };

  return (
    <div className="editor">
      <Editor
        spellCheck
        stripPastedStyles={false}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'blockType', 'list'],
          inline: {
            options: ['bold', 'italic', 'underline']
          },
          blockType: {
            options: ['Normal']
          },
          list: {
            options: ['unordered', 'ordered']
          }
        }}
      />
    </div>
  );
};

RichTextfieldComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default RichTextfieldComponent;
