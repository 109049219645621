/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Navigate } from 'react-router-dom';

// MUI
import {
  TextField,
  Container,
  Button,
  FormControl,
  Select,
  Box,
  Switch,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Grid,
  Typography
} from '@mui/material';

import RichTextfieldComponent from '../Utils/UIComponents/RichTextfieldComponent';

// Svschema
const eventSchema = yup.object().shape({
  title: yup.string().required(),
  program: yup.string().required(),
  categories: yup.array().of(yup.string().min(24).max(24)),
  subcategory: yup.string().min(24).max(24),
  audience: yup.string().required(),
  prerequisites: yup.string().required(),
  objectives: yup.string().required(),
  modalities: yup.string().required(),
  duration: yup.string().required(),
  location: yup.string().required(),
  intra: yup.bool().required(),
  inter: yup.bool().required(),
  certification: yup.bool().required(),
  display: yup.bool().required()
});

// prettier-ignore
const NewAtelierForm = ({
  createAtelier,
  categories,
  subcategories,
  redirectUrl
}) => {
  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  const [atelierId, setAtelierId] = useState(null);
  const [catList, setCatList] = useState([]);
  const [subcatList, setSubcatList] = useState([]);
  const [selectedCats, setSelectedCats] = useState([]);

  // Hook form init
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(eventSchema),
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setCatList(categories
        .filter((category) => category.subcategories
          .find((subcat) => subcat.subCatTitle === 'ateliers')));

      const foundAtelier = subcategories.find((subcategory) => subcategory.subCatTitle === 'ateliers');

      if (foundAtelier) {
        setSubcatList([foundAtelier]);
        setAtelierId(foundAtelier._id);
      }
    }
    return () => {
      isMounted = false;
    }; // cleanup toggles value, if unmounted
  }, [categories, subcategories]);

  const handleAddEvent = (data) => {
    const formData = { ...data, categories: selectedCats, subcategory: atelierId };
    console.log('creating atelier :', formData);
    createAtelier(formData);
  };

  const handleChangeCategory = (event) => {
    console.log('event', event);
    const {
      target: { value },
    } = event;
    setSelectedCats(typeof value === 'string' ? value.split(',') : value);
  };

  const CustomTextFieldInput = ({
    name, label, multiline, type
  }) => (
    <FormControl fullWidth>
      <Controller
        name={name}
        defaultValue={''}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            id={name}
            data-testid={name}
            type={type || 'text'}
            label={label}
            multiline={multiline}
            errors={`errors.${name}`}
            helperText={`errors.${name}` && `${label} requis`}
            {...field}
          />
        )}
      />
    </FormControl>
  );

  return (
    <Container
      className="addAtelierForm"
      sx={{
        maxWidth: '600px',
        paddingBottom: '2rem',
      }}
    >
      <Typography variant="h5" textAlign="left" paddingBottom="1rem">
        Créer un nouvel atelier
      </Typography>
      <form className="addAtelierForm-form" data-testid="addAtelierForm-form" onSubmit={handleSubmit(handleAddEvent)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            textAlign: 'left',
          }}
        >
          {/* TITLE */}
          <CustomTextFieldInput
            className="addAtelierForm-input"
            name={'title'}
            multiline={false}
            label={"Nom de l'atelier"}
          />
          {/* PROGRAM */}
          <>
            <Typography id="program-label" sx={{ color: '#888' }}>
              Programme de l'atelier :
            </Typography>

            <FormControl fullWidth>
              <Controller
                className="addEventForm-input"
                name={'program'}
                control={control}
                defaultValue={''}
                label={"Programme de l'atelier :"}
                rules={{ required: true }}
                render={({ field }) => <RichTextfieldComponent {...field} />}
              />
            </FormControl>
          </>
          {/* Category */}
          <FormControl fullWidth variant="outlined" error={!!errors.categories}>
            <>
              <InputLabel id="category-label">Catégorie</InputLabel>
              <Select
                id="categories"
                onChange={handleChangeCategory}
                inputProps={{ 'data-testid': 'category' }}
                labelId="category-label"
                label="Choisissez une catégorie"
                value={selectedCats}
                multiple
              >
                {catList &&
                  catList.length > 0 &&
                  catList.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.catTitle}
                    </MenuItem>
                  ))}
              </Select>
            </>

            {errors.categories && <FormHelperText>La categorie est requise</FormHelperText>}
          </FormControl>
          {/* Audience */}
          <CustomTextFieldInput
            className="addAtelierForm-input"
            name={'audience'}
            multiline={true}
            label={"Public de l'atelier"}
          />
          {/* Pre requisites */}
          <CustomTextFieldInput
            className="addAtelierForm-input"
            name={'prerequisites'}
            multiline={true}
            label={'Pré-requis'}
          />
          {/* OBJECTIVES */}
          <>
            <Typography id="objectives-label" sx={{ color: '#888' }}>
              Objectifs de l'atelier :
            </Typography>

            <FormControl fullWidth>
              <Controller
                className="addEventForm-input"
                name={'objectives'}
                control={control}
                defaultValue={''}
                label={"Objectifs de l'atelier :"}
                rules={{ required: true }}
                render={({ field }) => <RichTextfieldComponent {...field} />}
              />
            </FormControl>
          </>
          { /* MODALITIES */ }
          <>
            <Typography id="Modalities-label" sx={{ color: '#888' }}>
              Modalités pédagogiques :
            </Typography>
            <FormControl fullWidth>
              <Controller
                className="addAtelierForm-input"
                name={'modalities'}
                control={control}
                defaultValue={''}
                label={'Modalités pédagogiques :'}
                rules={{ required: true }}
                render={({ field }) => <RichTextfieldComponent {...field} />}
              />
            </FormControl>
          </>

          {/* <CustomTextFieldInput
            className="addAtelierForm-input"
            name={'modalities'}
            multiline={true}
            label={'Modalités pédagogiques'}
          /> */}
          {/* DURATION */}
          <CustomTextFieldInput
            className="addAtelierForm-input"
            name={'duration'}
            multiline={false}
            label={"Durée de l'atelier"}
          />
          {/* LOCATION */}
          <CustomTextFieldInput className="addAtelierForm-input" name={'location'} multiline={false} label={'Lieu'} />

          {/* SWITCHS INPUTS */}
          <Grid container spacing={2}>
            {/* INTRA */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  className="addAtelierForm-input"
                  name="intra"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel control={<Switch />} label="Intra-entreprise" errors={errors.intra} {...field} />
                  )}
                />
                {errors.intra && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
              </FormControl>
            </Grid>
            {/* INTER */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  className="addAtelierForm-input"
                  name="inter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel control={<Switch />} label="Inter-entreprise" errors={errors.inter} {...field} />
                  )}
                />
                {errors.inter && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
              </FormControl>
            </Grid>
            {/* CERTIFICATION */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  className="addAtelierForm-input"
                  name="certification"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch />}
                      label="Certifiante"
                      errors={errors.certification}
                      {...field}
                    />
                  )}
                />
                {errors.certification && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
              </FormControl>
            </Grid>
            {/* DISPLAY */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Controller
                  className="addAtelierForm-input"
                  name="display"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel control={<Switch />} label="Visible" errors={errors.display} {...field} />
                  )}
                />
                {errors.display && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            data-testid="submitBtn"
            variant="contained"
            className="addAtelierForm-btn"
            onClick={() => console.log('clicked on button')}
          >
            Créer atelier
          </Button>
        </Box>
      </form>
    </Container>
  );
};

NewAtelierForm.propTypes = {
  // COMPONENT PROPTYPES
  redirectUrl: PropTypes.string,
  createAtelier: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      catTitle: PropTypes.string
    })
  ),
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      catTitle: PropTypes.string
    })
  ),
  // CUSTOM TEXTFIELD PROPTYPES
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  multiline: PropTypes.bool
};

NewAtelierForm.defaultProps = {
  type: '',
  name: '',
  label: '',
  multiline: true
};

export default NewAtelierForm;
