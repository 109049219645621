import { connect } from 'react-redux';
import AddSubcatToCatComponent from '../components/CategoriesComponents/AddSubcatToCatComponent';

import { updateCategorySubcategoriesList } from '../actions/categories';

const mapStateToProps = (state) => ({
  subcategories: state.events.subcategories,
  categories: state.events.categories,
});

const mapDispatchToProps = (dispatch) => ({
  updateCategorySubcategoriesList: (payload) => dispatch(updateCategorySubcategoriesList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSubcatToCatComponent);
