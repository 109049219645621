import PropTypes from 'prop-types';

// MUI
import { Chip } from '@mui/material';

const ChipComponent = ({ label = '', color = 'default' }) => (
  <Chip
    style={{
      marginBottom: '0.5rem',
    }}
    label={label.charAt(0).toUpperCase() + label.slice(1)}
    color={color}
  />
);

ChipComponent.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
};

ChipComponent.defaultProps = {
  color: 'default',
};

export default ChipComponent;
