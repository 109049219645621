// eslint-disable-next-line arrow-body-style
const useFormatDate = (date) => {
  return new Date(date).toLocaleString('fr-FR', {
    weekDay: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export default useFormatDate;
