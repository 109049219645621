/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// prettier-ignore

import { Navigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// MUI
import {
  TextField,
  Container,
  Button,
  FormControl,
  Select,
  Box,
  Switch,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Grid,
  Typography
} from '@mui/material';

import RichTextfieldComponent from '../Utils/UIComponents/RichTextfieldComponent';
import Loading from '../Utils/UIComponents/Loading';

// Svschema
const atelierSchema = yup.object().shape({
  title: yup.string().required(),
  program: yup.string().required(),
  categories: yup.array().of(yup.string().min(24).max(24)),
  subcategory: yup.string().min(24).max(24),
  audience: yup.string().required(),
  prerequisites: yup.string().required(),
  objectives: yup.string().required(),
  modalities: yup.string().required(),
  duration: yup.string().required(),
  location: yup.string().required(),
  intra: yup.bool().required(),
  inter: yup.bool().required(),
  certification: yup.bool().required(),
  display: yup.bool().required()
});

// prettier-ignore
const EditAtelierForm = ({
  updateAtelier,
  categories,
  subcategories,
  redirectUrl,
  atelier,
  getAtelierDetails,
  isLoading
}) => {
  // console.log('atelier', atelier, 'subcategories', subcategories);

  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  const { id } = useParams();

  // EVENT STATE
  const [event, setEvent] = useState(null);

  const [isInter, setIsInter] = useState(undefined);
  const [isIntra, setIsIntra] = useState(undefined);
  const [isCertif, setIsCertif] = useState(undefined);
  const [isVisible, setIsVisible] = useState(undefined);

  // FORM STATE
  const [catList, setCatList] = useState([]);
  const [atelierSubcat, setAtelierSubcat] = useState('');
  const [selectedCats, setSelectedCats] = useState([]);

  const [programInput, setProgramInput] = useState('');
  const [objectivesInput, setObjectivesInput] = useState(null);

  // Hook form init
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(atelierSchema),
  });

  // Get event details
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getAtelierDetails(id);
    }
    return () => {
      isMounted = false;
    }; // cleanup toggles value, if unmounted
  }, []);

  // set categories & subcategories select's lists
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setCatList(categories);

      const foundAtelier = subcategories.find((subcategory) => subcategory.subCatTitle === 'ateliers');

      if (foundAtelier) {
        setAtelierSubcat(foundAtelier._id);
      }
    }
    return () => {
      isMounted = false;
    }; // cleanup toggles value, if unmounted
  }, [categories, subcategories]);

  // Set event details
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setEvent(atelier);
      setIsVisible(atelier?.display);
      setIsCertif(atelier?.certification);
      setIsInter(atelier?.inter);
      setIsIntra(atelier?.intra);

      // Set categories if not already selected
      atelier?.categories?.forEach((cat) => {
        const foundInArray = selectedCats.find((item) => item === cat._id);
        if (!foundInArray) {
          setSelectedCats((prevState) => [...prevState, cat._id]);
        }
      });
    }
    return () => {
      isMounted = false;
    }; // cleanup toggles value, if unmounted
  }, [atelier]);

  // Set values in form
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setValue('title', event?.title);
      selectedCats.forEach((cat) => {
        setValue('categories', [cat?._id]);
      });
      setValue('subcategory', atelierSubcat);
      setValue('audience', event?.audience);
      setValue('prerequisites', event?.prerequisites);
      setValue('objectives', event?.objectives);
      setValue('modalities', event?.modalities);
      setValue('duration', event?.duration);
      setValue('location', event?.location);
      setValue('intra', isIntra);
      setValue('inter', isInter);
      setValue('certification', isCertif);
      setValue('display', isVisible);
    }
    return () => {
      isMounted = false;
    }; // cleanup toggles value, if unmounted
  }, [event]);

  // SET VALUES FOR RICH TEXTFIELDS
  useEffect(() => {
    setProgramInput(event?.program);
    setObjectivesInput(event?.objectives);
  }, [event]);

  // FORM UTILS
  const handleChangeCategory = (cat) => {
    setSelectedCats(cat.target.value);
  };

  const handleUpdateEvent = (data) => {
    const formData = {
      ...data,
      categories: selectedCats,
      inter: isInter,
      intra: isIntra,
      certification: isCertif,
      display: isVisible
    };
    console.log('creating atelier :', formData);
    updateAtelier(id, formData);
  };

  // prettier-ignore
  const CustomTextFieldInput = ({
    name,
    defaultValue,
    required,
    label,
    multiline,
    type
  }) => (
    <FormControl fullWidth>
      <Controller
        className="editAtelierForm-input"
        name={name}
        defaultValue={defaultValue ?? ''}
        control={control}
        rules={{ required: required }}
        render={({ field }) => (
          <TextField
            id={name}
            type={type || 'text'}
            label={label}
            multiline={multiline}
            errors={`errors.${name}`}
            helperText={`errors.${name}` && `${label} requis`}
            {...field}
          />
        )}
      />
    </FormControl>
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Container
          className="editAtelierForm"
          sx={{
            maxWidth: '600px',
            padding: '1rem 1rem 2rem',
          }}
        >
          <Typography variant="h5" textAlign="left" paddingBottom="1rem">
            Modifier l'atelier
          </Typography>
          <form className="editAtelierForm-form" onSubmit={handleSubmit(handleUpdateEvent)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                textAlign: 'left',
              }}
            >
              {/* TITLE */}
              <CustomTextFieldInput
                name={'title'}
                defaultValue={event?.title}
                required={true}
                multiline={false}
                label={'Nom de la formation'}
              />
              {/* PROGRAM */}
              {programInput && (
                <FormControl fullWidth>
                  <Controller
                    className="editAtelierForm-input"
                    name={'program'}
                    control={control}
                    defaultValue={`${programInput}`}
                    label={"Programme de l'atelier"}
                    rules={{ required: true }}
                    render={({ field }) => <RichTextfieldComponent {...field} />}
                  />
                </FormControl>
              )}
              {/* Categories */}
              <FormControl fullWidth variant="outlined" error={!!errors.categories}>
                <>
                  <InputLabel id="category-label">Catégories</InputLabel>
                  <Select
                    id="categories"
                    onChange={handleChangeCategory}
                    inputProps={{ 'data-testid': 'category' }}
                    labelId="category-label"
                    label="Choisissez une catégorie"
                    value={selectedCats}
                    multiple
                  >
                    {catList &&
                      catList.length > 0 &&
                      catList.map((category) => (
                        <MenuItem key={category._id} value={category._id}>
                          {category.catTitle}
                        </MenuItem>
                      ))}
                  </Select>
                </>
                {errors.categories && <FormHelperText>La categorie est requise</FormHelperText>}
              </FormControl>
              {/* Sub Category */}
              <FormControl fullWidth variant="outlined" error={!!errors.subcategory}>
                <InputLabel id="subcategory-label" sx={{ backgroundColor: '#fff', padding: '0 0.5rem' }}>
                  Sous-catégorie
                </InputLabel>
                <>
                  <Select id="subcategory" labelId="subcategory-label" value={atelierSubcat} displayEmpty>
                    <MenuItem key={event?.subcategory?._id} value={event?.subcategory?._id}>
                      {event?.subcategory?.subCatTitle}
                    </MenuItem>
                  </Select>
                </>
                {errors.subcategory && <FormHelperText>Sous categorie requise</FormHelperText>}
              </FormControl>
              {/* Audience */}
              <CustomTextFieldInput
                name={'audience'}
                defaultValue={event?.audience}
                required={true}
                multiline={true}
                label={"Cible de l'atelier"}
              />
              {/* Pre requisites */}
              <CustomTextFieldInput
                name={'prerequisites'}
                defaultValue={event?.prerequisites}
                required={true}
                multiline={true}
                label={'Pré-requis'}
              />
              {/* OBJECTIVES */}
              {objectivesInput && (
                <>
                  <Typography id="objectives-label" sx={{ color: '#888' }}>
                    Objectifs de la formation :
                  </Typography>
                  <FormControl fullWidth>
                    <Controller
                      className="editEventForm-input"
                      name={'objectives'}
                      control={control}
                      defaultValue={`${objectivesInput}`}
                      label={'Objectifs de la formation'}
                      rules={{ required: true }}
                      render={({ field }) => <RichTextfieldComponent {...field} />}
                    />
                  </FormControl>
                </>
              )}
              {/* MODALITIES */}
              <>
                <Typography id="Modalities-label" sx={{ color: '#888' }}>
                  Modalités pédagogiques :
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    className="addAtelierForm-input"
                    name={'modalities'}
                    control={control}
                    defaultValue={`${event?.modalities}`}
                    label={'Modalités pédagogiques :'}
                    rules={{ required: true }}
                    render={({ field }) => <RichTextfieldComponent {...field} />}
                  />
                </FormControl>
              </>

              {/* <CustomTextFieldInput
                name={'modalities'}
                defaultValue={event?.modalities}
                required={true}
                multiline={true}
                label={'Modalités pédagogiques'}
              /> */}
              {/* DURATION */}
              <CustomTextFieldInput
                name={'duration'}
                defaultValue={event?.duration}
                required={true}
                multiline={false}
                label={"Durée de l'atelier"}
              />
              {/* LOCATION */}
              <CustomTextFieldInput
                name={'location'}
                defaultValue={event?.location}
                required={true}
                multiline={false}
                label={'Lieu'}
              />
              {/* SWITCH INPUTS */}
              <Grid container spacing={2}>
                {/* INTRA */}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Controller
                      className="editAtelierForm-input"
                      name="intra"
                      control={control}
                      defaultValue={isIntra ?? false}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Switch checked={isIntra ?? false} onChange={(e) => setIsIntra(e.target.checked)} />}
                          label="Intra-entreprise"
                          errors={errors.intra}
                          {...field}
                        />
                      )}
                    />
                    {errors.intra && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
                  </FormControl>
                </Grid>
                {/* INTER */}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Controller
                      className="editAtelierForm-input"
                      name="inter"
                      control={control}
                      defaultValue={isInter ?? false}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Switch checked={isInter ?? false} onChange={(e) => setIsInter(e.target.checked)} />}
                          label="Inter-entreprise"
                          errors={errors.inter}
                          {...field}
                        />
                      )}
                    />
                    {errors.inter && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
                  </FormControl>
                </Grid>
                {/* CERTIFICATION */}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Controller
                      className="editAtelierForm-input"
                      name="certification"
                      control={control}
                      defaultValue={isCertif ?? false}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch checked={isCertif ?? false} onChange={(e) => setIsCertif(e.target.checked)} />
                          }
                          label="Certifiante"
                          errors={errors.certification}
                          {...field}
                        />
                      )}
                    />
                    {errors.certification && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
                  </FormControl>
                </Grid>
                {/* DISPLAY */}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Controller
                      className="editAtelierForm-input"
                      name="display"
                      control={control}
                      defaultValue={event?.display}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isVisible ?? false}
                              type="checkbox"
                              onChange={(e) => setIsVisible(e.target.checked)}
                            />
                          }
                          label="Visible"
                          errors={errors.display}
                          {...field}
                        />
                      )}
                    />
                    {errors.display && <FormHelperText>Merci de renseigner ce champ</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>
              {/* SUBMIT BUTTON */}
              <Button type="submit" variant="contained" className="editAtelierForm-btn">
                Enregistrer les modifications
              </Button>
            </Box>
          </form>
        </Container>
      )}
    </>
  );
};

EditAtelierForm.propTypes = {
  // COMPONENT PROPS
  redirectUrl: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,

  updateAtelier: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  subcategories: PropTypes.array.isRequired,
  getAtelierDetails: PropTypes.func.isRequired,
  atelier: PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    program: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        catTitle: PropTypes.string
      })
    ),
    subcategory: PropTypes.shape({
      subCatTitle: PropTypes.string,
      _id: PropTypes.string
    }),
    audience: PropTypes.string,
    prerequisites: PropTypes.string,
    objectives: PropTypes.string,
    modalities: PropTypes.string,
    duration: PropTypes.string,
    location: PropTypes.string,
    intra: PropTypes.bool,
    inter: PropTypes.bool,
    certification: PropTypes.bool,
    display: PropTypes.bool
  }),

  // CUSTOM INPUT PROPS
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string
};

EditAtelierForm.defaultProps = {
  name: '',
  defaultValue: '',
  required: false,
  label: '',
  multiline: false,
  type: 'text',

  atelier: PropTypes.shape({
    _id: '',
    title: '',
    program: '',
    audience: '',
    prerequisites: '',
    objectives: '',
    modalities: '',
    duration: '',
    location: '',
    intra: false,
    inter: false,
    certification: false,
    display: false
  })
};

export default EditAtelierForm;
